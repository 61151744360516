.otgPreviewSection.inOTGPage {
  background-size: cover, auto 120%;
  background-position: center, center 90%;
}

.otgPreviewSection.cinemaType {
  /* background-image :
            linear-gradient(rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%),
            url('../src/assets/images/game-page/OTG-cinema-preview-section.jpg');
    background-size: cover, auto 100%;
    background-position: center, center 100%; */
  background-image: linear-gradient(
      rgba(0, 0, 0, 0) 100%,
      rgba(0, 0, 0, 0) 100%
    ),
    url("../src/assets/images/game-page/OTG-cinema-preview-section.jpg");
  background-size: cover;
  background-position: left bottom;
  -webkit-box-shadow: inset 0px 0px 120px 64px rgba(0, 0, 0, 0.68);
  -moz-box-shadow: inset 0px 0px 120px 64px rgba(0, 0, 0, 0.68);
  box-shadow: inset 0px 0px 120px 64px rgba(0, 0, 0, 0.68);
}

.game-about-section {
  padding: 130px 0;
  position: relative;
}

.game-about-section .content {
  /*max-width: 1470px;*/
  padding-right: 60px;
  display: flex;
  overflow: hidden;
}

.game-about-section .about-image {
  width: 50.92%;
  max-width: 940px;
  height: 548px;
  background-image: url(../src/assets/images/game-page/about-image.jpeg);
  background-position: center right;
  background-size: cover;
  opacity: 0.9;
  box-shadow: inset -150px 0px 88px 0px rgb(0 0 0 / 100%);
  transition: 0.1s ease-out;
}

.game-about-section .about-image.cinemaType {
  background-image: url(../src/assets/images/game-page/cinema-about-image.jpg);
}

.game-about-section .about-description-size-holder {
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  overflow-x: hidden;
  transition: 0.1s ease-out;
}

.green {
  color: rgba(175, 255, 0, 1);
}

.game-about-section .about-description-size-holder .title {
  /*for fadeIn animation boilerplate*/
  opacity: 0;
  transform: translateX(-50%);

  margin-left: auto;
  max-width: 600px;
  margin-right: 80px;
  font-family: Supply, sans-serif;
  font-size: 26px;
  font-weight: 700;
  /* line-height: 64px; */
  line-height: 1;
  color: rgba(175, 255, 0, 1);
  text-transform: uppercase;
  opacity: 0;
  transform: translateX(-50%);
}
.game-about-section .about-description-size-holder .title span {
  font-size: 30px;
}

.game-about-section .about-description-size-holder .title.cinemaType {
  margin-left: auto;
  margin-right: 173px;
  font-size: 42px;
}

.game-about-section
  .about-description-size-holder
  .title.cinemaType
  .title-top-part.ua {
  font-size: 36px;
}

.game-about-section.about-description-size-holder.title.about-description-text {
  margin-left: auto;
  max-width: 600px;
  margin-right: 80px;
  font-family: Supply, sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 1;
}

.game-about-section .about-description-text {
  margin-top: 30px;
  margin-left: auto;
  margin-right: 80px;
  max-width: 600px;
  font-size: 16px;
  line-height: 24px;

  /*for fadeIn animation boilerplate*/
  opacity: 0;
  transform: translateX(-50%);
}

.game-about-section .redirect-button {
  margin-top: 48px;
  margin-left: auto;
  margin-right: 386px;
  width: 295px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Supply, sans-serif;
  font-size: 14px;
  font-weight: 700;
  border: 0.5px solid rgba(175, 255, 1, 0.4);
  color: rgba(175, 255, 1, 1);
  background: rgba(175, 255, 1, 0.12);
  box-shadow: inset 0 0 0 0 #afff01;
  transition: box-shadow ease-in 0.07s, color ease-in 0.07s,
    opacity ease-in 0.07s;
  cursor: pointer;

  /*for fadeIn animation boilerplate*/
  opacity: 0;
  transform: translateX(50%);
}

.game-about-section .redirect-button:hover {
  box-shadow: inset 0 0 0 2em #afff01, 0 0 16px 0 rgba(175, 255, 1, 0.4);
  opacity: 0.9;
  color: #000;
}

/*min-width*/
@media (min-width: 992px) {
  .react-reveal .footer {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 1911px) {
  .game-about-section .content {
    max-width: 1911px;
    margin: 0 auto;
  }
}

@media (max-width: 1500px) {
  .game-about-section .about-image {
    width: 58%;
    height: 501px;
    box-shadow: inset -150px 0px 88px 0px rgb(0 0 0 / 100%);
  }

  .game-about-section .redirect-button {
    margin-right: 335px;
  }
  .game-about-section .about-description-size-holder .title {
    margin-right: 30px;
  }

  .game-about-section.about-description-size-holder.title.about-description-text {
    margin-right: 30px;
  }

  .game-about-section .about-description-text {
    margin-right: 30px;
  }
}

/*max-width*/
@media (max-width: 1200px) {
  .game-about-section .about-description-size-holder {
    max-width: 960px;
    margin: 0 auto;
  }

  .game-about-section .about-image.cinemaType {
    background-position: center center;
  }

  .game-about-section .about-description-size-holder .title {
    margin-right: 247px;
  }

  .game-about-section .about-description-text {
    margin-right: 14px;
  }

  .game-about-section .about-description-size-holder .title.cinemaType {
    margin-right: 105px;
  }

  .game-about-section .redirect-button {
    margin-right: 317px;
  }
}

@media (max-width: 1078px) {
  .game-about-section .about-description-size-holder {
    max-width: 89vw;
  }
}

@media (max-width: 992px) {
  .game-about-section {
    padding: 95px 15px;
  }

  .game-about-section .about-image {
    background-position-y: 10%;
    background-position-x: right;
  }

  .game-about-section .content {
    /*absolute button height + 60px margin*/
    padding-bottom: calc(48px + 60px);

    padding-right: 0;
    flex-direction: column-reverse;
    gap: 25px;
    position: relative;
  }

  .game-about-section .about-description-size-holder {
    max-width: 100%;
    order: 1;
    position: initial;
    transform: none;
  }

  .game-about-section .about-description-size-holder .title {
    margin: 0;
    font-size: 30px;
    line-height: normal;
  }
  .game-about-section .about-description-size-holder .title span {
    font-size: 20px;
  }

  .game-about-section .about-description-size-holder .title.cinemaType {
    margin-left: 0;
    margin-right: 0;
    font-size: 30px;
  }

  .game-about-section .about-description-text {
    margin: 32px 0 0 0;
    max-width: 100%;
  }

  .game-about-section .redirect-button {
    margin: 0;
    position: absolute;
    bottom: 0;
    left: calc(50% - 147.5px);
    /*transform: translateX(-50%);*/
  }

  .game-about-section .about-image {
    margin-top: 60px;
    width: 100%;
    height: calc((100vw - 30px) * 0.4479);
    order: 2;
  }

  .otgPreviewSection.cinemaType {
    background-position: 10% bottom;
  }
}

@media (max-width: 768px) {
  .otgPreviewSection.cinemaType .content {
    padding-bottom: 7vh;
    justify-content: flex-end;
  }

  .otgPreviewSection.cinemaType .redirect-button {
    margin-top: 30px;
  }
}

@media (max-width: 580px) {
  .otgPreviewSection.cinemaType {
    background-position: 16% bottom;
  }
}

@media (max-width: 554px) {
  .game-about-section .about-image {
    margin-left: -15px;
    /* margin: none; */
    /*margin-top: 32px;*/
    box-shadow: none;
    width: 100vw;
    height: 60vw;
    margin-top: 0px;
  }

  .game-about-section .content {
    /*absolute button height + 60px margin*/
    /*padding-bottom: calc(48px + 32px);*/
  }
}

@media (max-width: 400px) {
  .otgPreviewSection.cinemaType {
    background-position: 18% bottom;
  }
}

/*FadeIn animations*/
.otgPreviewSection .otgLogo.fadeIn {
  /*animation: fadeInFromLeft40 0.4s ease-out forwards;*/
  animation: fadeInFromTop35 0.4s ease-out forwards;
}

.otgPreviewSection .redirect-button.fadeIn {
  /*animation: fadeInFromLeft40 0.4s ease-out 0.15s forwards;*/
  animation: fadeInFromBottom70 0.4s ease-out 0.15s forwards;
}

.otgPreviewSection .platforms-icons.fadeIn {
  /*animation: fadeInFromLeft10 0.6s cubic-bezier(.25,.75,.5,1.25) 0.3s forwards;*/
  animation: fadeInFromBottom70 0.4s ease-out 0.15s forwards;
}

.game-about-section .about-description-size-holder .title.fadeIn {
  /*animation: fadeInFromRight50 0.4s ease-out forwards;*/
  animation: fadeInFromLeft10 0.4s ease-out forwards;
}

@media (max-width: 992px) {
  .game-about-section .about-description-size-holder .title.fadeIn {
    /*animation: fadeInFromRight50 0.4s ease-out forwards;*/
    animation: fadeInFromLeft20 0.4s ease-out forwards;
  }
}

.game-about-section .about-description-text.fadeIn {
  /*animation: fadeInFromRight7 0.4s ease-out 0.15s forwards;*/
  animation: fadeInFromLeft10 0.4s ease-out 0.15s forwards;
}

.game-about-section .redirect-button.fadeIn {
  /*animation: fadeInFromRight50 0.6s cubic-bezier(.25,.75,.5,1.25) 0.3s forwards;*/

  animation: fadeInFromLeft40 0.6s cubic-bezier(0.25, 0.75, 0.5, 1.25) 0.3s
    forwards;
  /*animation: fadeInFromLeft10 0.4s ease-out 0.3s forwards;*/
}

/*fade in from left side animations*/
@keyframes fadeInFromLeft10 {
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromLeft20 {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromLeft40 {
  from {
    opacity: 0;
    transform: translateX(-40%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/*fade in from right side animations*/
/*@keyframes fadeInFromRight7 {*/
/*    0% {opacity: 0; transform: translateX(7%)}*/
/*    100% {opacity: 1; transform: translateX(0)}*/
/*}*/

/*@keyframes fadeInFromRight50 {*/
/*    from {opacity: 0; transform: translateX(50%)}*/
/*    to {opacity: 1; transform: translateX(0)}*/
/*}*/

/*fade in vertical basics 'Gunzilla' banner content animations*/
@keyframes fadeInFromTop35 {
  0% {
    opacity: 0;
    transform: translateY(-35%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInFromBottom70 {
  0% {
    opacity: 0;
    transform: translateY(70%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/*@keyframes fadeInFromBottom70Button {*/
/*    0% {*/
/*        opacity: 0;*/
/*        transform: translateY(70%)*/
/*    }*/
/*    100% {*/
/*        opacity: 1;*/
/*        transform: translateY(0)*/
/*    }*/
/*}*/

@import "./gunz-page.css";
@import "./otg-page.css";

@font-face {
  font-family: Montserrat;
  font-weight: normal;
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: Montserrat;
  font-weight: 600;
  src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: Montserrat;
  font-weight: 900;
  src: url("./assets/fonts/Montserrat-Black.ttf") format("truetype");
}

/* Supply */
/*
100	Thin (Hairline)
200	Extra Light (Ultra Light)
300	Light
400	Normal
500	Medium
600 Semi Bold (Demi Bold)
700	Bold
800	Extra Bold (Ultra Bold)
900	Black (Heavy)
*/

@font-face {
  font-family: Supply;
  font-weight: 200;
  src: url("./assets/fonts/supply/Supply-UltraLight.otf") format("opentype");
}
@font-face {
  font-family: Supply;
  font-weight: 300;
  src: url("./assets/fonts/supply/Supply-Light.otf") format("opentype");
}
@font-face {
  font-family: Supply;
  font-weight: 400;
  src: url("./assets/fonts/supply/Supply-Regular.otf") format("opentype");
}
@font-face {
  font-family: Supply;
  font-weight: 500;
  src: url("./assets/fonts/supply/Supply-Medium.otf") format("opentype");
}
@font-face {
  font-family: Supply;
  font-weight: 700;
  src: url("./assets/fonts/supply/Supply-Bold.otf") format("opentype");
}

/* 'Source Code Pro', monospace; */
@font-face {
  font-family: SourceCodePro;
  font-weight: 200;
  src: url("./assets/fonts/source-code-pro/SourceCodePro-ExtraLight.ttf")
    format("truetype");
}
@font-face {
  font-family: SourceCodePro;
  font-weight: 300;
  src: url("./assets/fonts/source-code-pro/SourceCodePro-Light.ttf")
    format("truetype");
}
@font-face {
  font-family: SourceCodePro;
  font-weight: 400;
  src: url("./assets/fonts/source-code-pro/SourceCodePro-SemiBold.ttf")
    format("truetype");
}
@font-face {
  font-family: SourceCodePro;
  font-weight: 500;
  src: url("./assets/fonts/source-code-pro/SourceCodePro-SemiBold.ttf")
    format("truetype");
}
@font-face {
  font-family: SourceCodePro;
  font-weight: 600;
  src: url("./assets/fonts/source-code-pro/SourceCodePro-SemiBold.ttf")
    format("truetype");
}
@font-face {
  font-family: SourceCodePro;
  font-weight: 700;
  src: url("./assets/fonts/source-code-pro/SourceCodePro-Bold.ttf")
    format("truetype");
}
@font-face {
  font-family: SourceCodePro;
  font-weight: 800;
  src: url("./assets/fonts/source-code-pro/SourceCodePro-Black.ttf")
    format("truetype");
}

/* NeueMontreal */
@font-face {
  font-family: NeueMontreal;
  font-weight: 300;
  src: url("./assets/fonts/neue-montreal/NeueMontreal-Light.otf")
    format("opentype");
}
@font-face {
  font-family: NeueMontreal;
  font-weight: 400;
  src: url("./assets/fonts/neue-montreal/NeueMontreal-Regular.otf")
    format("opentype");
}
@font-face {
  font-family: NeueMontreal;
  font-weight: 500;
  src: url("./assets/fonts/neue-montreal/NeueMontreal-Medium.otf")
    format("opentype");
}
@font-face {
  font-family: NeueMontreal;
  font-weight: 700;
  src: url("./assets/fonts/neue-montreal/NeueMontreal-Bold.otf")
    format("opentype");
}

/*OpenSans*/
@font-face {
  font-family: Open Sans;
  font-weight: 400;
  src: url("./assets/fonts/open-sans/OpenSans.ttf") format("truetype");
}
@font-face {
  font-family: Open Sans;
  font-weight: 500;
  src: url("./assets/fonts/open-sans/OpenSans.ttf") format("truetype");
}
@font-face {
  font-family: Open Sans;
  font-weight: 600;
  src: url("./assets/fonts/open-sans/OpenSans.ttf") format("truetype");
}
@font-face {
  font-family: Open Sans;
  font-weight: 700;
  src: url("./assets/fonts/open-sans/OpenSans.ttf") format("truetype");
}

#root {
  padding-top: 140px;
}
.mgb {
  margin-bottom: 32px;
  display: inline-block;
}
.popup-is-opened {
  overflow-y: hidden;
  height: 100%;
  touch-action: none;
  width: 100%;
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-right: 10px;
}
body {
  font-family: NeueMontreal;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #ccc;
  background-color: #000;
  /* background-image: url(./assets/images/bg_main@2x.png); */
  background-repeat: repeat;
  background-position: 100% 100%;
  caret-color: transparent;
}

input,
textarea {
  caret-color: auto;
  border-radius: 0;
  -webkit-appearance: none;
}

body.ua {
  font-family: "Open Sans", sans-serif;
}

section {
  max-width: 100%;
}

a,
button,
.button {
  cursor: pointer;
  text-decoration: none;
  color: #1b1a21;
}

a {
  text-decoration: none;
  transition: all ease-out 0.2s;
  color: rgba(175, 255, 1, 0.8);
}
a:hover {
  text-decoration: none;
  transition: all ease-out 0.2s;
  color: rgba(175, 255, 1, 1);
}
ul {
  margin: 0px;
  padding-left: 16px;
}
.row > div {
  position: static;
}

/* HEADER */
.header {
  margin-bottom: 0px;
  padding: 35px 0px;
  /* padding: 14px 0px; */
  background-color: transparent;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;
  border-bottom: 2px solid transparent;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.popup-is-opened .header {
  background-color: #000000;
  border-bottom: 2px solid #afff00;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 14px 0px;
}

.popup-is-opened .header.mobile.isTop-true {
  z-index: 3;
  transition: none;
}

.header.isTop-false {
  background-color: #000000;
  border-bottom: 2px solid #afff00;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 14px 0px;
}

.header .menu ul {
  list-style: none;
  margin: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0px;
}

.header .menu ul li {
  margin: 0px 20px;
  display: flex;
  align-items: center;
}

.vc {
  background-color: #afff01;
  padding: 2px 5px;
  color: #000;
  margin-left: 5px;
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  line-height: 1em;
  font-family: Supply;
}
.ua .vc {
  font-family: SourceCodePro;
  font-weight: 900;
}

.header a {
  text-decoration: none;
  font-family: Supply;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  white-space: nowrap;
}
.ua .header a {
  font-family: SourceCodePro;
}

.header ul li a.is-active,
.menu-content .menu-items a.is-active {
  color: rgba(255, 255, 255, 1) !important;
}

.header a:hover {
  color: rgba(255, 255, 255, 1);
}

.logoHeader {
  width: 100%;
  max-width: 142px;
}

.header .menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.header .hiretop {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
}
.iconsWhite {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.iconsWhite > a {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  transition: all ease-out 0.2s;
  background-color: rgba(255, 255, 255, 0.6);
}
.iconsWhite > a:hover {
  background-color: rgba(255, 255, 255, 1);
  transition: all ease-out 0.2s;
}

.iconsWhite > a:first-child {
  margin-left: 0;
}

.iconsWhite .facebook {
  background-size: 9.5px 18px;
  background-position: center 100%;
  background-image: url(./assets/images/icons/ic_facebook.svg);
}
.iconsWhite .instagram {
  background-size: 16px 16px;
  background-image: url(./assets/images/icons/ic_instagram.svg);
}
.iconsWhite .twitter {
  background-size: 15.6px 12.5px;
  background-image: url(./assets/images/icons/ic_twitter.svg);
}
.iconsWhite .linkedin {
  background-size: 16px 16px;
  background-image: url(./assets/images/icons/ic_linkedin.svg);
}

/* HEADER2 */
.header2 {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.01) 12%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.01) 12%),
    url(./assets/images/bg_main_dark.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 245px;
  padding-bottom: 136px;
  margin-top: -75px;
  margin-bottom: 96px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.header2-logo img {
  width: 100%;
  margin-bottom: 48px;
  max-width: 100%;
}

.header2-text h1 {
  font-family: Supply;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: #fff;
  margin-bottom: 40px;
}
.ua .header2-text h1 {
  font-family: SourceCodePro;
}

.header2-text p {
  font-family: Supply;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: #000000;
}
.ua .header2-text p {
  font-family: SourceCodePro;
}
/* ABOUT */
.about h2 {
  font-family: Supply;
  font-size: 48px;
  font-weight: bold;
  line-height: 1.08;
  color: #afff01;
  margin-bottom: 40px;
  text-transform: uppercase;
}
.ua .about h2 {
  font-family: SourceCodePro;
}
.about-cards {
  margin-bottom: 56px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.about-cards .card {
  width: calc(20% - 1px);
  margin-right: 1px;
  height: 428px;
  position: relative;
  overflow: hidden;
  background-color: black;
  border-radius: 0px;
}
.about-cards .card .content {
  padding: 20px 16px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0.8) 99%
  );
}
.about-cards .card .hover {
  padding: 20px 16px 80px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
      309.01deg,
      rgba(1, 1, 1, 0.25) -32.16%,
      rgba(34, 34, 34, 0.25) 127.4%
    ),
    #121212;
}

.about-cards .card:last-child {
  width: calc(20%);
  margin-right: 0px;
}
.about-cards .title {
  font-family: Supply;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #fafafa;
  line-height: 1.5;
  margin-bottom: 16px;
  min-height: 40px;
  text-align: left;
  position: relative;
  width: 100%;
  max-width: 100%;
  top: 70%;
}
.ua .about-cards .title {
  font-family: SourceCodePro;
}
.about-cards .hover .title {
  top: unset;
  color: #afff01;
}
.about-cards .desc {
  /* font-family: NeueMontreal; */
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  opacity: 1;
  text-align: left;
}
.about-cards .content .desc {
  display: none;
}
.about .about-content {
  font-size: 16px;
  line-height: 1.43;
  margin-bottom: 56px;
}

.about .about-content p {
  color: rgba(250, 250, 250, 0.8);
}

.about .about-content h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  margin-bottom: 32px;
  color: #e5e5e5;
  font-family: Supply;
}

.ua .about .about-content h3 {
  font-size: 20px;
  font-weight: 900;
  font-family: SourceCodePro;
}

.about-gallery > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
}
.about-gallery .wide {
  grid-column: span 2;
}

.about-gallery img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}

.about .about-gallery .imgWrapper > div {
  position: relative;
}
/*
.about .about-gallery .imgWrapper > div::after{
  content: "";
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all .3s ease-out;
  background-color: rgba(175,255,1,.12);
  cursor: pointer;
}
.about .about-gallery .imgWrapper > div:hover::after{
  transition: all .05s ease-out;
  background-color: rgba(175,255,1,0);
  z-index: -2;
  cursor: pointer;
}
*/
.about .about-gallery .imgWrapper * {
  cursor: pointer !important;
  outline: none;
}

[data-rmiz-wrap="visible"],
[data-rmiz-wrap="hidden"] {
  display: flex !important;
  outline: none;
}

.about .about-gallery .imgWrapper {
  overflow: hidden;
}
.about .about-gallery .imgWrapper img {
  transform: scale(1);
  transition: all ease-in-out 0.2s;
}
.about .about-gallery .imgWrapper:hover img {
  transform: scale(1.2);
  transition: all ease-in-out 0.2s;
}

/* FLIP BOX 3D */
.flip-box {
  perspective: 1000px;
  width: 100%;
  height: 100%;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
  transition: transform 0.4s;
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-box-front {
}

.flip-box-back {
  transform: rotateY(180deg);
}

/* FOOTER */
.footer {
  padding: 24px 0px;
  border-top: 2px solid #afff01;
}
.footer .logo-footer img {
  width: 100%;
  max-width: 143px;
  margin-top: 11px;
}
.footer .iconsWhite {
  align-items: flex-start;
  margin-top: 11px;
}
.footer .logo {
  display: flex;
  align-items: flex-start;
}
.footer a {
  color: rgba(175, 255, 1, 0.8);
}
.footer a:hover {
  color: rgba(175, 255, 1, 1);
}
.footer .icons {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.footer .icons a,
.icons-light a,
.iconsGreen a {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: 0px 5px 5px 5px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all ease-out 0.2s;
  background-color: rgba(175, 255, 1, 0.8);
}
.footer .icons a:hover,
.icons-light a:hover,
.popoup-content .icons > a:hover {
  background-color: rgba(175, 255, 1, 1);
  transition: all ease-out 0.2s;
}
.footer .icons a:last-child {
  margin-right: 0px;
}
.footer .icons a:first-child {
  margin-left: 0px;
}

.iconsGreen .facebook {
  background-size: 9.5px 18px;
  background-position: center 100% !important;
  background-image: url(./assets/images/icons/ic_facebook.svg);
}
.iconsGreen .instagram {
  background-size: 16px 16px;
  background-image: url(./assets/images/icons/ic_instagram.svg);
}
.iconsGreen .twitter {
  background-size: 15.6px 12.5px;
  background-image: url(./assets/images/icons/ic_twitter.svg);
}
.iconsGreen .linkedin {
  background-size: 16px 16px;
  background-image: url(./assets/images/icons/ic_linkedin.svg);
}
.iconsGreen .personal {
  background-size: 16px 16px;
  background-image: url(./assets/images/icons/ic_web.svg);
}
.iconsGreen .artstation {
  background-size: 16px 16px;
  background-image: url(./assets/images/icons/ic_artstation.svg);
}

.subscribe-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.footer .subscribe {
  padding-right: 0px;
  width: 100%;
  max-width: 295px;
  transition: 0.2s;
}
.subscribe input[type="text"] {
  width: 100%;
  max-width: 350px;
  box-sizing: border-box;
  border: none !important;
  outline: none !important;
  border-bottom: 0.5px solid rgba(250, 250, 250, 0.48) !important;
  box-shadow: none !important;
  padding: 8px 32px 8px 0px;
  background-color: transparent;
  font-size: 14px;
  line-height: 1.43;
  color: rgba(250, 250, 250, 0.5);
  font-family: Supply;
  transition: all ease-out 0.2s;
}
.ua .subscribe input[type="text"] {
  font-family: SourceCodePro;
  /* transition: 1s; */
}

.subscribe input.error-true {
  border-bottom: 0.5px solid rgba(255, 69, 0, 0.4);
}

.footer .subscribe h3 {
  font-family: Supply;
  font-size: 16px;
  line-height: 1.5;
  color: #fafafa;
  margin-bottom: 0px;
}
.ua .footer .subscribe h3 {
  font-family: SourceCodePro;
}
.subscribe input[type="text"]:hover,
.subscribe input[type="text"]:active,
.subscribe input[type="text"]:focus {
  outline: none;
  color: rgba(250, 250, 250, 1);
  box-shadow: none !important;
  border-bottom: 0.5px solid #afff01 !important;
  transition: all ease-out 0.2s;
  border-radius: 0;
  -webkit-appearance: none;
}
.subscribe-button {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-left: -24px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(./assets/images/icons/subscribe-button.svg);
  opacity: 0.8;
  margin-bottom: -8px;
  display: inline-block;
  border: none;
  background-color: transparent;
  transition: all ease-out 0.2s;
}
.footer .subscribe:hover .subscribe-button,
.subscribe-button:hover,
.subscribe-button:active {
  opacity: 1;
  background-image: url(./assets/images/icons/subscribe-button-hover.svg);
  transition: all ease-out 0.2s;
}

.footer .subscribe.true input[type="text"] {
  color: #afff01;
  transition: 0.5s;
}
.footer .subscribe.true .subscribe-button {
  visibility: hidden;
  opacity: 0;
  transition: 0.2s;
}

.footer .copyright {
  /* font-family: NeueMontreal; */
  font-size: 12px;
  line-height: 26px;
  color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 48px;
  margin-bottom: 24px;
}
.footer .copyright p {
  margin: 0px;
}
.footer .copyright span {
  display: inline-block;
  /* width: 100%; */
}

.latest-news a.read-more {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: #364de1;
}
.latest-news {
  padding: 0px 0px;
}
.latest-news h2 {
  font-family: Supply;
  font-size: 48px;
  font-weight: bold;
  line-height: 1.08;
  color: #afff01;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.ua .latest-news h2 {
  font-family: SourceCodePro;
}

.animationCardWrap {
  height: 100%;
}

.last-slide-adapting {
  margin-bottom: 0 !important;
}

.last-slide-adapting:last-child {
  height: 100%;
}

.news-card {
  position: relative;

  height: 100%;
}
.news-card .link {
  display: block;
  height: 100%;
}
.news-card .title {
  font-family: Supply;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ua .news-card .title {
  font-family: SourceCodePro;
}
.container1 {
  padding: 16px;
}

.container1 .tags {
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
}
.latest-news .container1 {
  padding: 16px;
  z-index: 1;
  /* height: 100%; */
  flex-grow: 3;
  flex-shrink: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
      309.01deg,
      rgba(1, 1, 1, 0.25) -32.16%,
      rgba(34, 34, 34, 0.25) 127.4%
    ),
    rgba(18, 18, 18, 0.8);
}

.news-card .short-desc {
  /* font-family: NeueMontreal; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #fafafa;
}

.news-card img {
  max-width: 100%;
  width: 100%;
}

.news-card .date {
  /* font-family: NeueMontreal; */
  color: rgba(250, 250, 250, 0.48);
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

/* LEADERSHIP */
.leadership {
  margin-bottom: 96px;
}
.see-all-leadership.button {
  display: block;
  margin-top: 50px;
  margin-left: auto;
}
.leadership h2 {
  font-family: Supply;
  font-size: 48px;
  font-weight: bold;
  line-height: 1.08;
  color: #afff01;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.ua .leadership h2 {
  font-family: SourceCodePro;
}
.leadership .poster {
  position: relative;
  overflow: hidden;
  border-bottom: 4px solid #afff01;
  box-sizing: border-box;
}
.leadership .leader-hover {
  position: absolute;
  bottom: -80%;
  left: 0;
  right: 0;
  background-color: rgba(175, 255, 1, 0.82);
  padding: 8px 16px 16px;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all ease-out 0.8s;
}
.leadership .poster img {
  filter: grayscale(100%);
  transition: all ease-out 0.2s;
}
.leadership .poster:hover {
}
.leadership .poster:hover img {
  filter: grayscale(0%);
  transition: all ease-out 0.1s;
}
.leadership .poster:hover .leader-hover {
  bottom: 0;
  transition: all ease-out 0.2s;
}
.leadership-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 34px;
  grid-row-gap: 34px;
}
.leader-card {
  cursor: pointer;
}
.leader-card .title {
  font-family: Supply;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
  margin-top: 8px;
  margin-bottom: 4px;
}
.ua .leader-card .title {
  font-family: SourceCodePro;
}
.leader-card .short-desc {
  font-family: Supply;
  font-size: 16px;
  line-height: 1.5;
}
.ua .leader-card .short-desc {
  font-family: SourceCodePro;
}

.leader-card img {
  max-width: 100%;
  width: 100%;
}

.popup {
  background-color: rgb(0, 0, 0, 0.8);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: -10;
  opacity: 0.001;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all ease-out 0.3s;
  -webkit-overflow-scrolling: touch;
}

.popup.true {
  visibility: visible;
  display: flex;
  z-index: 1000;
  opacity: 0.999;
  transition: all ease-out 0.3s;
  -webkit-overflow-scrolling: touch;
}

.popoup-content {
  border: solid 4px #afff01;
  background-color: #000000;
  padding: 56px;
  max-width: 1140px;
  position: relative;
  cursor: auto;
  color: #fafafa;
  overflow-y: auto;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
}
.popoup-content .close {
  width: 24px;
  height: 24px;
  background-image: url(./assets/images/path-2@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}
.popoup-content .title {
  font-family: Supply;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.4;
  color: #afff01;
  margin-top: 0px;
  margin-bottom: 8px;
  text-transform: uppercase;
  text-align: left;
}
.ua .popoup-content .title {
  font-family: SourceCodePro;
}
.popoup-content .role {
  font-family: Supply;
  font-size: 16px;
  line-height: 1.5;
  color: #afff01;
  margin-bottom: 32px;
}
.ua .popoup-content .role {
  font-family: SourceCodePro;
}
.popoup-content .desc {
  font-size: 16px;
  /* font-family: NeueMontreal; */
  font-size: 16px;
  line-height: 1.5;
  color: #fafafa;
}
.popoup-content .icons {
  display: flex;
  justify-content: flex-start;
}
.popoup-content .icons > a {
  display: inline-block;
  width: 32px !important;
  height: 32px !important;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 12px 12px 12px 0px !important;
}

.popoup-content .photo {
  margin-bottom: 12px;
  border-bottom: 4px solid #afff01;
}
.popoup-content .rightPanel {
  padding-left: 45px;
  padding-right: 45px;
}

/* CAREERS */
.career {
  padding-bottom: 130px;
}
.career-content {
  font-size: 16px;
}
.career h1 {
  text-transform: uppercase;
  font-family: Supply;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: #afff01;
  margin-bottom: 32px;
}
.ua .career h1 {
  font-family: SourceCodePro;
}
.career h3 {
  font-family: Supply;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #afff01;
}
.ua .career h3 {
  font-family: SourceCodePro;
}
.vacancy-control {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 23px;
}
.vacancy-search {
  margin-bottom: 55px;
  grid-area: 1/1/1/3;
  position: relative;
}
.vacancy-search input {
  width: 100%;
  color: rgba(250, 250, 250, 1);
  font-size: 16px;
  line-height: 1.5;
  padding: 12px 16px 12px 24px;
  border: none;
  border-bottom: 0.5px solid rgba(250, 250, 250, 0.48);
  box-sizing: border-box;
  background-color: #000;
  background-image: url(./assets/images/magnify@2x.png);
  background-position: 0px center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  font-family: Supply;
}
.button-clear {
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(./assets/images/icons/ic_x_clear.svg);
  background-size: contain;
  position: absolute;
  right: 0px;
  top: calc(50% - 9px);
  cursor: pointer;
  opacity: 0.8;
  transition: all ease-in-out 0.3s;
  z-index: 1;
}
.button-clear.false {
  z-index: -1;
  opacity: 0.001;
  transition: all ease-in-out 0.3s;
}
.button-clear:hover {
  transition: all ease-in-out 0.3s;
  opacity: 1;
}

.ua .vacancy-search input {
  font-family: SourceCodePro;
}
.vacancy-search input:focus,
.vacancy-search input:hover {
  outline: none;
  color: rgba(250, 250, 250, 1);
  border-bottom: 0.5px solid rgba(175, 255, 1, 0.48);
}
.vacancies-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 23px;
  grid-row-gap: 24px;
  position: relative;
}

.vacancies-grid > div {
}

.overflow-hidden {
  overflow: hidden;
}

.pagination {
  margin-top: 16px;
}
.pagination > * {
  display: block;
  width: 48px;
  height: 48px;
  border: solid 0.5px rgba(175, 255, 1, 0.2);
  background-color: rgba(175, 255, 1, 0.12);
  line-height: 48px;
  text-align: center;
  margin-right: 16px;
  cursor: pointer;
  color: #fff;
  font-family: Supply;
  font-size: 20px;
  font-weight: bold;
  line-height: 46px;
  box-shadow: inset 0 0 0 0em #afff01;
  transition: all ease-in 0.15s;
}
.pagination > div:hover {
  transition: all ease-in 0.15s;
  color: #000;
  /* background-color: #afff01; */
  box-shadow: inset 0 0 0 2em #afff01;
}
.ua .pagination > * {
  font-family: SourceCodePro;
}
.pagination > .current {
  background-color: #afff01;
  color: #000000;
}

.vacancy-card-holder {
  min-height: 140px;
}
.vacancy-card {
  background: linear-gradient(
      309.01deg,
      rgba(1, 1, 1, 0.25) -32.16%,
      rgba(34, 34, 34, 0.25) 127.4%
    ),
    #121212;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  transition: all ease-out 0.2s;
  position: absolute;
  min-height: 140px;
}

.label-new {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #afff01;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  line-height: 1em;
  padding: 2px 6px;
  font-family: Supply;
}

.ua .label-new {
  font-family: SourceCodePro;
  font-weight: 900;
}

.flip-box-back .label-new {
  background-color: transparent;
}

.vacancy-card .title {
  font-family: Supply;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #afff01;
  margin-bottom: auto;
}
.ua .vacancy-card .title {
  font-family: SourceCodePro;
}
.vacancy-card .location {
  /* font-family: NeueMontreal; */
  font-size: 12px;
  line-height: 1.33;
  color: rgba(250, 250, 250, 0.48);
  display: flex;
  justify-content: space-between;
}
.vacancy-card .card-location {
  color: rgba(255, 255, 255, 0.8);
  padding-left: 15px;
  background-image: url(./assets/images/icons/location-gray.svg);
  background-position: left 1px;
  background-repeat: no-repeat;
  background-size: 9px 12px;
}
.location-divider {
  padding: 0 3px;
}
.loc:last-child .location-divider {
  display: none;
}
.date-location {
  white-space: nowrap;
}

.vacancy-card.hover {
  background-color: #afff01;
  transition: all ease-in 0.2s;
}
.vacancy-card.hover .title,
.vacancy-card.hover .location {
  color: #000;
}
.vacancy-card.hover .card-location {
  background-image: url(./assets/images/icons/location-black.svg);
  color: #000;
}

.vacancy-card .card-role {
  color: rgba(255, 255, 255, 0.8);
  padding-left: 15px;
  background-image: url(./assets/images/icons/ic_role_white.svg);
  background-position: left 2px;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  margin-top: 14px;
}
.vacancy-card.hover .card-role {
  background-image: url(./assets/images/icons/ic_role.svg);
  color: #000;
}

.home-perks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;
  margin: 0px;
  padding: 0px;
}
.home-perks li {
  list-style: none;
  font-size: 14px;
  line-height: 1.43;
  margin-bottom: 16px;
  padding-left: 30px;
  background-image: url(./assets/images/marker-green.svg);
  background-repeat: no-repeat;
  background-position: left 0px;
  background-size: 20px;
  position: relative;
  cursor: pointer;
}

.perk-hint {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0px;
  top: 0px;
  list-style: none;
  font-size: 14px;
  line-height: 1.43;
  margin-bottom: 16px;
  padding-right: 30px;
  background-image: url(./assets/images/PerkHint.svg);
  background-repeat: no-repeat;
  background-position: left 0px;
  background-size: 20px;
  cursor: pointer;
}

.home-perks .liperk1 {
  background-image: url(./assets/images/Perk1.svg);
}

.home-perks .liperk2 {
  background-image: url(./assets/images/Perk2.svg);
}

.home-perks .liperk3 {
  background-image: url(./assets/images/Perk3.svg);
}

.home-perks .liperk4 {
  background-image: url(./assets/images/Perk4.svg);
}

.home-perks .liperk5 {
  background-image: url(./assets/images/Perk5.svg);
}

.home-perks .liperk6 {
  background-image: url(./assets/images/Perk6.svg);
}

.home-perks .liperk7 {
  background-image: url(./assets/images/Perk7.svg);
}

.perk-tooltip {
  position: absolute;
  max-width: 250px;

  left: 0px;
  opacity: 0;
  transition: all ease-out 0.2s;
  transform: translateY(55px) scale(0);
  transform-origin: 20% 60%;
  padding: 24px;
  border-radius: 4px;
  background-color: #afff01;
  color: #000;
  z-index: 1;
}
.perk-tooltip::after {
  content: "";
  left: 15%;
  width: 15px;
  height: 15px;
  background-color: #afff01;
  transform: rotate(45deg);
  position: absolute;
  bottom: -5px;
}

.home-perks li:hover .perk-tooltip {
  transition: all ease-out 0.2s;
  transform: translateY(0px) scale(1);
  opacity: 1;
}

.perks-header {
  margin-bottom: 16px;
}

/* TOOLTIP START*/
.e-tooltip-wrap {
  border-radius: 4px !important;
  opacity: 1;
}
.e-tooltip-wrap.e-popup {
  border-radius: 4px !important;
  background-color: #afff01;
  border: 2px solid #000;
}

.e-tooltip-wrap .e-tip-content {
  color: #000;
  border-radius: 4px !important;
  font-size: 13px;
  line-height: 1.43em;
  padding: 21px;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-bottom {
  border-left: 12px solid transparent;
  border-right: 14px solid transparent;
  border-top: 12px solid #000;
}

.e-tooltip-wrap .e-arrow-tip-inner.e-tip-bottom {
  color: #afff01;
  font-size: 25.9px;
}

.e-tooltip-wrap .e-arrow-tip-inner.e-tip-top {
  color: #afff01;
}

.e-tooltip-wrap .e-arrow-tip.e-tip-bottom {
  height: 12px;
  left: 50%;
  top: 100%;
  width: 24px;
}

.tooltip-box {
  cursor: pointer !important;
  display: inline-block !important;
}

/* TOOLTIP END */

/* r-dropdown */
.r-dropdown {
  display: block;
  position: relative;
  margin-bottom: 24px;
  cursor: pointer;
  user-select: none;
  font-family: Supply;
}
.ua .r-dropdown {
  font-family: SourceCodePro;
}
.r-dropdown span {
  padding: 14px 16px;
  display: inline-block;
  width: 100%;
}
.r-dropdown ul {
  width: 100%;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
  visibility: hidden;
  border-top: solid 0.5px rgba(175, 255, 1, 0.2);
  border-bottom: solid 0.5px rgba(175, 255, 1, 0.2);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 11px #afff01; */
  background-color: #608809;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #afff01;
  border-radius: 8px;
}

.r-dropdown li {
  list-style: none;
  position: absolute;
  left: 0px;
  width: 100%;
  opacity: 0;
  border-left: solid 0.5px rgba(175, 255, 1, 0.2);
  border-right: solid 0.5px rgba(175, 255, 1, 0.2);
  background-color: #121212;
  transition: all ease 0.3s;
  z-index: -1;
}
.r-dropdown > span {
  display: inline-block;
  border-bottom: solid 0.5px rgba(250, 250, 250, 0.48);
  position: relative;
}
.r-dropdown > span > strong {
  font-weight: 600;
  padding-left: 1em;
  color: #afff01;
}
.r-dropdown > span:after {
  display: inline-block;
  transition: all ease 0.2s;
  content: "\25BE";
  color: #fafafa;
  font-size: 28px;
  font-weight: 900;
  line-height: 20px;
  margin-left: 20px;
  position: absolute;
  right: 15px;
}
.r-dropdown.active ul {
  visibility: visible;
}
.r-dropdown.active > span:after {
  transition: all ease 0.2s;
  transform: rotate(180deg);
}
.r-dropdown li:hover {
  transition: all ease 0s;
  background-color: #252f10;
  color: #fafafa;
}

.r-dropdown li.left {
  margin-left: -100px;
}
.r-dropdown li.right {
  margin-left: 100px;
}
.r-dropdown.active li {
  transition: all ease 0.3s;
  opacity: 1;
  margin-left: 0px;
  z-index: 100;
}

/* VACANCY PAGE */
.vacancy-page {
  padding-bottom: 130px;
}
.vacancy-page h1 {
  font-family: Supply;
  font-size: 48px;
  font-weight: bold;
  line-height: 1.08;
  color: #afff01;
  margin-bottom: 40px;
  text-transform: uppercase;
}
.vacancy-page h2,
.vacancy-page h3 {
  font-family: Supply;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  color: #fafafa;
  margin-top: 32px;
  margin-bottom: 16px;
  text-transform: uppercase;
}
.ua .vacancy-page h1,
.ua .vacancy-page h2,
.ua .vacancy-page h3 {
  font-family: SourceCodePro;
}
.vacancy-page .text {
  /* font-family: NeueMontreal; */
  font-size: 16px;
  margin-bottom: 40px;
}
.vacancy-page ul {
  margin-bottom: 16px;
  color: #ccc;
}

.vacancy-page .vacancy-card {
  margin-bottom: 16px;
}

.vacancy-page .field {
  margin-bottom: 24px;
}
.vacancy-page .button {
  margin-bottom: 24px;
}

.more-vacancies {
  margin-bottom: 64px;
}
.vacancy-additional {
  border-top: 1px solid #999696;
  width: 100%;
  /*padding-top: 23px;*/
}
.vacancy-page .right-column i {
  font-size: 24px;
  color: #fafafa;
  width: 24px;
  text-align: center;
  margin-right: 16px;
}
.vacancy-page .right-column h3 {
  font-size: 14px;
  line-height: 1.43;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.vacancy-page .right-column .rslider-control > .slide-right {
  margin-right: 0px;
}

.linkedin-button {
  background-color: #0277b5;
  background-image: url(./assets/images/group-7@2x.png);
  background-repeat: no-repeat;
  background-position: 12px center;
  background-size: 24px 24px;
  padding: 14px 24px 14px 44px;
  font-family: Supply;
  color: #fafafa;
  outline: none;
  margin-bottom: 16px;
  max-width: 300px;
  height: 48px;
  border: 1px solid #999;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
}
.ua .linkedin-button {
  font-family: SourceCodePro;
}
.linkedin-button:hover {
  color: #fafafa;
  background-color: #03699f;
}

.vacancy-page div.field {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  min-height: 24px;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 24px 24px;
}

.field.date {
  background-image: url(./assets/images/calendar@2x.png);
}
.field.location {
  background-image: url(./assets/images/ic_map.svg);
}
.field.job-type {
  background-image: url(./assets/images/ic_case.svg);
}
.field.job-department {
  background-image: url(./assets/images/icons/ic_role_white.svg);
}

.fields {
  margin-bottom: 48px;
}
.share-title {
  width: 100%;
  text-align: center;
  font-family: Supply;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin-top: 96px;
  margin-bottom: 38px;
}
.ua .share-title {
  font-family: SourceCodePro;
}

.share-buttons .icons > * > div {
  width: 40px;
  height: 40px;
  filter: grayscale(100%);
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: center center;
}

.share-buttons .icons > * > div:hover {
  filter: grayscale(0);
}

.share-buttons .icons .facebook {
  background-image: url("./assets/images/icons/fb-share-green.svg");
}
.share-buttons .icons .twitter {
  background-image: url("./assets/images/icons/tw-share-green.svg");
}
.share-buttons .icons .linkedin {
  background-image: url("./assets/images/icons/linkedin-share-green.svg");
}
.share-buttons .icons .email {
  background-image: url("./assets/images/icons/email-share-green.svg");
}

.share-buttons {
  max-width: 100%;
  width: 300px;
  margin-bottom: 48px;
  height: 48px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(
      309.01deg,
      rgba(1, 1, 1, 0.25) -32.16%,
      rgba(34, 34, 34, 0.25) 127.4%
    ),
    #121212;
}

.share-buttons .conteiner {
  width: 100%;
  height: 200%;
  position: absolute;
  top: 0;
  transition: all ease-in-out 0.2s;
}

.first-column .share-buttons .conteiner {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.first-column .share-buttons:hover .conteiner {
  top: 0;
}

.first-column .share-buttons .title {
  padding-left: 16px;
  padding-right: 16px;
}

.share-buttons:hover .conteiner {
  top: -100%;
  transition: all ease-in-out 0.2s;
}

.share-buttons .icons {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share-buttons button {
  outline: none;
}

.share-buttons .title {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Supply;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.ua .share-buttons .title {
  font-family: SourceCodePro;
}

.share-buttons .title > div {
  padding-left: 32px;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url("./assets/images/icons/share.svg");
}

.share-buttons2 {
  margin-top: 32px;
  margin-bottom: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.share-buttons2 > * {
  width: 30px;
  height: 30px;
  margin: 6px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all ease-in-out 0.2s;
}
.share-buttons2 > *:hover {
  transition: all ease-in-out 0.2s;
}

.share-buttons2 .facebook {
  background-image: url(./assets/images/icons/share-facebook-white.svg);
}
.share-buttons2 .twitter {
  background-image: url(./assets/images/icons/share-twitter-white.svg);
}
.share-buttons2 .linkedin {
  background-image: url(./assets/images/icons/share-linkedin-white.svg);
}
.share-buttons2 .instagram {
  background-image: url(./assets/images/icons/share-instagram-white.svg);
}

.share-buttons2 .facebook:hover {
  background-image: url(./assets/images/icons/share-facebook.svg);
}
.share-buttons2 .twitter:hover {
  background-image: url(./assets/images/icons/share-twitter.svg);
}
.share-buttons2 .linkedin:hover {
  background-image: url(./assets/images/icons/share-linkedin.svg);
}
.share-buttons2 .instagram:hover {
  background-image: url(./assets/images/icons/share-instagram.svg);
}

.share-buttons2 > * > svg {
  margin-right: 8px;
}

.share-buttons2 > *:focus {
  outline: none;
}

section.didntfind {
  /* background-image: url(./assets/images/bg_didnt_find_a_job.jpg); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.didnt-find h6 {
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: #afff01;
  margin-bottom: 20px;
  font-family: Supply;
}

.ua .didnt-find h6 {
  font-family: SourceCodePro;
}

.didnt-find {
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 155px;
  padding-bottom: 155px;
}
.didnt-find p {
  font-family: NeueMontreal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #fafafa;
  margin-bottom: 40px;
}

.didnt-find p span {
  color: #afff01;
  font-family: Supply;
}

.ua .didnt-find p span {
  font-family: SourceCodePro;
}

.ua .didnt-find p {
  font-family: "Open Sans", sans-serif;
}

.didnt-find a {
  background: rgba(175, 255, 1, 0.12);
  border: 0.5px solid rgba(175, 255, 1, 0.4);
  font-family: Supply;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding: 14px;
  min-width: 265px;
  display: inline-block;
  box-shadow: inset 0 0 0 0em #afff01;
  transition: all ease-in 0.07s;
  text-transform: uppercase;
}

.ua .didnt-find a {
  font-family: "Open Sans", sans-serif;
}

.didnt-find a:hover {
  box-shadow: inset 0 0 0 2em #afff01;
  transition: all ease-in 0.07s;
  color: #000;
}

/* OUR GAME */
.our-game h1 {
  font-family: Supply;
  font-size: 48px;
  font-weight: bold;
  line-height: 1.08;
  color: #afff01;
  margin-bottom: 32px;
  text-transform: uppercase;
}
.ua .our-game h1 {
  font-family: SourceCodePro;
}

section.our-game {
  margin-top: 0px;
}

.our-game .text {
  font-family: Supply;
  font-size: 20px;
  line-height: 1.2;
  color: #fafafa;
}

.ua .our-game .text {
  font-family: SourceCodePro;
}

.our-game-bg img {
  display: block;
  max-width: 100%;
  width: 100%;
}

.black-block {
  color: #fafafa;
  background-color: #1b1a21;
}

/* TEXT ANIMATION */
.header2-logo {
  transform: translate(0, -40%);
  opacity: 0;
}

.text-reveal2 {
  transform: translate(0, 50%);
  opacity: 0;
}

.header2-logo.true,
.text-reveal2.true {
  opacity: 1;
  transition: all ease-out 0.4s;
  transform: translate(0, 0);
}

.text-reveal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.text-reveal > * {
  display: inline-block;
  position: relative;
  transform: scaleX(0);
  transition: all ease-out 0.2s;
  transform-origin: 0 0;
}

.text-reveal > *:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  left: 0;
  background: #ffffff;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transform-origin: 100% 0;
  z-index: 2;
  transition: all ease-out 0.7s;
}

.text-reveal.true > * {
  display: inline-block;
  position: relative;
  transform: scaleX(1);
  transition: all ease-in-out 0.4s;
  transform-origin: 0 0;
}

.text-reveal.true > *:after {
  background: #ffffff;
  transform: matrix(0.00001, 0, 0, 1, 0, 0);
  transform-origin: 100% 0;
  z-index: 2;
  transition: all ease-in-out 0.7s;
}

/* NEWS PAGE */
section.article {
  margin-bottom: 130px;
  padding-top: 160px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1050px;
  /* padding-top: 96px; */
  padding-top: 0px;
}
section.article.wide {
  max-width: unset;
}
section.article h1 {
  font-family: Supply;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.1;
  color: #afff01;
  margin-bottom: 40px;
}

section.article img {
  max-width: 100%;
}
section.article center {
  margin-bottom: 20px;
}

.ua section.article h1 {
  font-family: SourceCodePro;
}

.article .poster {
  width: 100%;
  margin-bottom: 35px;
}

.article .date {
  /* font-family: NeueMontreal; */
  font-size: 16px;
  line-height: 1.33;
  color: rgba(255, 255, 255, 0.6);
  display: inline-block;
}

.article-info {
  padding-bottom: 16px;
  margin-bottom: 40px;
  border-bottom: 1px solid #afff01;
}

.tag {
  padding-right: 12px;
  /* font-family: NeueMontreal; */
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(175, 255, 1, 0.8);
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  transition: all ease-out 0.2s;
}
.tag:hover {
  color: rgba(175, 255, 1, 1);
  transition: all ease-out 0.2s;
}

.article-info span.tag:last-of-type {
  padding-right: 24px;
}

section.article-navigation {
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  padding-top: 21px;
  padding-bottom: 21px;
  width: 100%;
  top: 65px;
}

.article-navigation-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
}
.back-link {
  white-space: nowrap;
}
.article-navigation-container span {
  color: #fff;
  padding-right: 4px;
  display: inline-block;
}
.v-divider {
  background-color: rgba(255, 255, 255, 0.8);
  margin: 0 16px;
  width: 1px;
  height: 100%;
  max-height: 22px;
}

section.newsletter {
  /* background-image: url(./assets/images/bg_newsletter.png); */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-bottom: 130px;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

section.newsletter .newsletter-container {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

section.newsletter .subscribe {
  display: flex;
  justify-content: center;
}

section.newsletter .title {
  font-family: Supply;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  color: #afff01;
  margin-bottom: 20px;
}
.ua section.newsletter .title {
  font-family: SourceCodePro;
}

section.newsletter .description {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #fafafa;
  margin-bottom: 40px;
}

.news-list {
  margin-bottom: 80px;
}

.press-wrapper {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
}

.press-wrapper .press-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;
  border: 10px solid #121212;
  padding: 60px;
  background-image: url(./assets/images/pressBack.png);
}

.press-wrapper .press-block .press-block__text {
  margin-bottom: 25px;
}

.press-wrapper .press-block .fullSize {
  width: 100%;
  display: block;
}

.press-wrapper .press-block h1 {
  width: 100%;
  font-size: 35px;
  display: flex;
  align-items: center;
}

.press-wrapper .press-block h1 .pr-icon {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-image: url(./assets/images/icons/pressPrIcon.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
}

.news-title-block {
  display: flex;
  justify-content: space-between;
}

.press-wrapper .press-block h1 .pr-kit-icon {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-image: url(./assets/images/icons/pressKitIcon.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
}

.card .tags {
  padding: 16px 16px 20px;
  min-height: 26px;
  box-sizing: content-box;
}
.news-list .card {
  background-color: #000;
}
.news-list h1 {
  font-family: Supply;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color: #afff01;
  text-transform: uppercase;
  margin-bottom: 32px;
}
.ua .news-list h1 {
  font-family: SourceCodePro;
}
.tags-filter {
  margin-bottom: 80px;
  overflow-x: auto;
}

.tags-filter .tag {
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  font-size: 20px;
  padding-right: 24px;
}
.tags-filter .tag.active,
.tags-filter .tag:hover {
  color: #afff01;
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 80px;
  row-gap: 80px;
  margin-bottom: 80px;
}

.news-grid img {
  max-width: 100%;
}

.news-grid h2 {
  font-family: Supply;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 10px;
}
.ua .news-grid h2 {
  font-family: SourceCodePro;
}

.news-grid .date {
  /* font-family: NeueMontreal; */
  font-size: 12px;
  line-height: 1.33;
  color: rgba(250, 250, 250, 0.48);
  margin-bottom: 16px;
}

.news-grid p {
  margin-bottom: 0px;
  color: rgba(255, 255, 255, 0.8);
}

.news-grid .card {
  height: 100%;
}

.animation-image-zoom {
  overflow: hidden;
}

.animation-image-zoom img {
  transition: all ease-in-out 0.25s;
}

.animation-image-zoom:hover > img {
  transition: all ease-in-out 0.25s;
  transform: scale(1.2);
}

/* NEWS END */

div.button.false {
  display: none;
}
.news-grid .card {
  border: none;
  background: linear-gradient(
      309.01deg,
      rgba(1, 1, 1, 0.25) -32.16%,
      rgba(34, 34, 34, 0.25) 127.4%
    ),
    #121212;
  border-radius: 0;
  overflow: hidden;
}

.link {
  /* font-family: NeueMontreal; */
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: #afff01;
}

.button {
  border: solid 0.2px rgba(175, 255, 1, 0.2);
  background-color: rgba(175, 255, 1, 0.12);
  font-family: Supply;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: #afff01;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 14px 37px;
  transition: all ease-in 0.07s !important;
  text-align: center;
  display: inline-block;
  width: 300px;
  max-width: 100%;
}
.ua .button {
  font-family: SourceCodePro;
}

.button:hover {
  transition: all ease-in 0.07s !important;
  /* background-color: #afff01; */
  box-shadow: inset 0 0 0 2em #afff01;
  color: #000;
}
.article-content {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-size: 16px;
  line-height: 30px;
}
.secondary {
  background-color: transparent;
  box-shadow: none;
  transition: all ease-in 0.2s !important;
}
.secondary:hover {
  color: #afff01;
  box-shadow: none;
  border: 0.5px solid #afff01;
  transition: all ease-in 0.2s !important;
}

.article-content h1 {
}
.article-content h2 {
  font-family: Supply;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  color: #ffffff;
}
.article-content h3 {
  font-family: Supply;
  color: #ffffff;
}
.article-content h4 {
  font-family: Supply;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}
.article-content h5 {
}
.article-content h6 {
  font-family: Supply;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  margin-bottom: 16px;
}

.ua .article-content h6 {
  font-family: SourceCodePro;
}

.article-content p {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
  color: rgba(250, 250, 250, 0.8);
}

.article-content a {
  color: rgba(175, 255, 0, 0.8);
}

.article-content a:hover {
  color: rgba(175, 255, 0, 1);
}

.article-content img {
  margin-bottom: 20px;
}

.article-content capture {
  margin-bottom: 40px;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.6);
}

.article-content blockquote {
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  color: rgba(250, 250, 250, 0.8);
  margin: 40px 10px 40px 25px;
  padding-left: 21px;
  padding-right: 21px;
  border-left: 1px solid #afff01;
}

.article-content blockquote p {
  line-height: 1.5em !important;
  font-size: 20px !important;
}

/* CONTACT US PAGE */
.contact-us {
  margin-bottom: 130px;
}
.contact-us h1 {
  font-family: Supply;
  font-size: 48px;
  font-weight: bold;
  line-height: 1.08;
  color: #afff01;
  margin-bottom: 40px;
  text-transform: uppercase;
}
.ua .contact-us h1 {
  font-family: SourceCodePro;
}
.contact-us .description {
  font-size: 16px;
}

.form-holder {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.form-holder input,
.form-holder textarea {
  margin-bottom: 16px;
  border: solid 0.5px rgba(175, 255, 1, 0.4);
  background-color: #000000;
  padding: 12px 16px;
  font-family: Supply;
  font-size: 16px;
  line-height: 1.5;
  color: rgba(250, 250, 250, 1);
  outline: none;
  transition: all ease-out 0.2s;
  border-radius: 0;
  -webkit-appearance: none;
}
.form-holder span.active-option {
  border: solid 0.5px rgba(175, 255, 1, 0.4);
  background-color: #000000;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 1.5;
  outline: none;
  transition: all ease-out 0.2s;
}
.form-holder .error-true span.active-option {
  border: solid 0.5px rgba(255, 69, 0, 0.6);
}
.form-holder span.active-option.a0 {
  color: rgba(250, 250, 250, 0.45);
}
.form-holder .r-dropdown {
  margin-bottom: 15px !important;
}

.ua .form-holder input,
.ua .form-holder textarea {
  font-family: SourceCodePro;
}

.form-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  /* for baseline alignment */
  margin-top: 4px;
}

.message-info {
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  color: #fafafa;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  position: absolute;
  visibility: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.01;
  transition: all ease-in-out 0.2s;
}

.message-info .title {
  font-family: Supply;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #afff01;
  margin-bottom: 8px;
}

.ua .message-info .title {
  font-family: SourceCodePro;
}

.message-info.true {
  z-index: 1;
  opacity: 1;
  visibility: visible;
  transition: all ease-in-out 0.2s;
}

.form-holder .field.error-true {
  border: solid 0.5px rgba(255, 69, 0, 0.6);
}

.form-holder input:focus,
.form-holder input:hover,
.form-holder textarea:focus,
.form-holder textarea:hover {
  border: solid 0.5px #afff01;
  transition: all ease-in 0.2s;
  border-radius: 0;
  -webkit-appearance: none;
}

.form-holder button {
  margin-top: 40px;
  align-self: flex-start;
  outline: none;
}

.clink {
  margin-bottom: 24px;
}

.clink h6 {
  /* font-family: NeueMontreal; */
  font-size: 16px;
  line-height: 1.5;
  color: #fafafa;
  margin-bottom: 4px;
}

.clink a {
  /* font-family: NeueMontreal; */
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #afff01 !important;
  opacity: 0.8;
}
.clink a:hover {
  opacity: 1;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* rslide */
.about-gallery2 {
  margin-bottom: 96px;
}
.about-gallery2 * {
  outline: none !important;
}
[data-rmiz-modal-content] img {
  max-width: 100% !important;
  max-height: 100% !important;
}
.rslide {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  transition: 0.3s;
}

.rslide > div {
  box-sizing: border-box;
  margin: 2px;
  z-index: 0;
  margin-bottom: 24px;
}

.about-gallery2 .rslide > div {
  margin-bottom: 4px;
}

.rslide img {
  max-width: 100%;
  width: 100%;
  position: relative;
}

.s1_4 .image-holder:first-child {
  margin-bottom: 4px;
}

.s1_4 {
  width: calc(33.33% - 4px);
  display: flex;
  flex-direction: column;
}

.s2_4 {
  width: calc(33.33% - 4px);
}

.s4_4 {
  width: calc(66.66% - 4px);
}

.image-holder {
  padding: 0;
  margin: 0;
  position: relative;
}

/* COOKIES POPUP */
.cookies-popup {
  /* font-family: NeueMontreal; */
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  color: rgba(250, 250, 250, 0.88);
  margin-right: auto;
  margin-left: auto;
  position: fixed;
  z-index: 200;
  width: 100%;
  bottom: 30px;
  padding-left: 24px;
  padding-right: 24px;
  opacity: 1;
  transition: all ease-out 0.8s;
}

.cookies-popup.false {
  bottom: -400px;
  opacity: 0;
  transition: all ease-out 0.8s;
}

.cookies-popup .container {
  max-width: 860px;
  background-color: #333;
  border: solid 0.2px rgba(175, 255, 1, 0.2);
  padding: 24px 22px;
}

.cookies-popup h3 {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: rgba(250, 250, 250, 0.88);
  margin: 0px 0px 8px;
}

.cookies-popup a {
  color: rgba(175, 255, 1, 0.8);
}

.cookies-popup p {
  margin: 0px;
}

.cookies-popup .container .row > div {
  align-items: center;
  display: flex;
}

.cookies-popup .button {
  padding: 14px 21px;
}

.mobile {
  display: none;
}

.show-more100 {
  width: 100%;
}

.gallery-desctop {
  margin-bottom: 96px;
}
.focusRing___1airF {
  outline: none !important;
}
.slideHorizontal___1NzNV {
  padding-left: 1px;
  padding-right: 1px;
}
.dotes {
  margin-bottom: 8px;
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dotes > button {
  display: block;
  background-color: #000;
  width: 8px;
  height: 8px;
  border: 1px solid #fafafa;
  border-radius: 50%;
  padding: 0px;
  margin: 0px 4px;
}

.dotes > button > span {
  display: none;
}

.dotes .carousel__dot--selected {
  background-color: #afff01;
  border: 1px solid #afff01;
}

.icons.mobile {
  display: none !important;
}

/* CMS SECTION */

section.cms {
  padding-top: 0px;
  margin-bottom: 80px;
  /* font-family: NeueMontreal; */
  font-size: 14px;
  line-height: 1.71;
  color: #ccc;
}

section.cms h1 {
  font-family: Supply;
  font-size: 48px;
  font-weight: bold;
  line-height: 1.08;
  color: #afff01;
  margin-bottom: 40px;
  text-transform: uppercase;
}

section.cms h2 {
  font-family: Supply;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  color: #fafafa;
  margin-bottom: 8px;
}

.ua section.cms h1,
.ua section.cms h2 {
  font-family: SourceCodePro;
}

section.cms .text h3,
section.cms .text h4,
section.cms .text h5 {
  font-family: Supply;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
  color: #fafafa;
  margin-bottom: 8px;
}

.ua section.cms .text h3,
.ua section.cms .text h4,
.ua section.cms .text h5 {
  font-family: SourceCodePro;
}

section.cms .text a {
  color: rgba(175, 255, 0, 0.8);
}

section.cms .text a:hover {
  color: rgba(175, 255, 0, 1);
}

section.cms p {
  margin-bottom: 40px;
}

.cms a {
  color: #afff01;
}

[data-rmiz-btn-close] {
  border: none !important;
  outline: none !important;
}

section.latest-news {
  margin-bottom: 80px;
}
section.latest-news .item {
  overflow: hidden;
}

section.latest-news .item img {
  overflow: hidden;
}

section.latest-news .rslider-control {
  margin-top: 66px;
}

.rslider-content,
.rslider-wrapper {
  transition: 0.3s;
}

.rslide.cols4 {
  margin-bottom: 50px;
}

a.more-articles {
  width: 285px;
  padding-left: 15px;
  padding-right: 15px;
}

.shorten-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.padding-side-zero {
  padding-left: 0px;
  padding-right: 0px;
}

.lang-cell {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  min-height: 35px;
}

.language-container {
  max-width: 200px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  cursor: pointer;
}

.language-container-mob {
  position: static;
  margin: 30px 0px 0px;
  justify-content: center;
}

.language-list {
  top: 100%;
  position: absolute;
  margin-top: 23px;
  border-top: 0.5px solid rgba(175, 255, 1, 0.2);
  border-bottom: 0.5px solid rgba(175, 255, 1, 0.2);
  opacity: 0;
  z-index: -1;
  transition: all ease 0.3s;
  min-width: 150px;
  visibility: hidden;
  transition-delay: 0.2s;
}
.language-container-mob .language-list {
  margin-top: 0;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
  background-color: #1c1b1b;
  position: fixed;
}
.language-container.true .language-list,
.language-container:hover .language-list {
  z-index: 100;
  opacity: 1;
  transition: all ease 0.3s;
  visibility: visible;
}

@media screen and (max-width: 992px) {
  .press-wrapper {
    flex-direction: column;
    gap: 25px;
  }
  .press-wrapper .press-block {
    width: 100%;
    padding: 35px;
  }

  .press-wrapper .press-block h1 {
    font-size: 25px;
  }

  .language-container:hover .language-list {
    z-index: 0;
    opacity: 0;
    /*transition: all ease 0.3s;*/
    visibility: hidden;
  }

  .language-container.true .language-list {
    z-index: 100;
    opacity: 1;
    transition: all ease 0.3s;
    visibility: visible;
  }
}

.language-current {
  border-radius: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Supply;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  transition: all ease 0.2s;
}
.ua .language-current {
  font-family: SourceCodePro;
}

.language-current:hover,
.language-container.true .language-current {
  transition: all ease 0.2s;
}

.element-visible {
  opacity: 0;
  transition: all ease 1s;
}

.element-visible.true {
  opacity: 1;
  transition: all ease 1s;
}
.menu .language-current > div {
  display: flex;
  align-items: center;
}
.menu .language-container .language-list {
  left: 0;
}
.menu .language-container .language-list a {
  font-size: 14px;
  line-height: 18px;
  text-transform: none;
}
/*
.language-current:after{
  display: inline-block;
  transition: all ease 0.2s;
  content: '\25BE';
  color: #fafafa;
  font-size: 18px;
  font-weight: 900;
  line-height: 20px;
  margin-left: 20px;
  position: absolute;
  right: 15px;
}

.language-container.true .language-current:after{
  transition: all ease 0.2s;
  transform: rotate(180deg);
}
*/

.language-icon {
  width: 20px;
  height: 20px;
  background-image: url(./assets/images/icons/lang-switcher.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 0.8;
  transition: all ease-in-out 0.2s;
}
.language-container.true .language-icon,
.language-icon:hover {
  transition: all ease-in-out 0.2s;
  opacity: 1;
}
.language-item {
  background-color: #121212;
  font-size: 14px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.8);
  padding: 13px 25px;
  border-left: solid 0.5px rgba(175, 255, 1, 0.2);
  border-right: solid 0.5px rgba(175, 255, 1, 0.2);
  position: relative;
  transition: all ease 0.3s;
}
.language-container-mob .language-item {
  border: none;
  background-color: transparent;
  padding: 13px 33px;
}
.language-container-mob .language-item.current-item {
  background-color: #000;
  background-image: url(./assets/images/icons/ic_left_arrow.svg);
  background-position: 13px center;
  background-size: 13px 10px;
  background-repeat: no-repeat;
  padding: 21px 33px;
}

.language-item.ua {
  font-family: "Open Sans", sans-serif;
}
.language-item:nth-child(2n + 1) {
  left: -100px;
  transition: all ease 0.3s;
  transition-delay: 0.2s;
}
.language-item:nth-child(2n) {
  left: 100px;
  transition: left ease 0.3s;
  transition-delay: 0.2s;
}
.language-container.true .language-item,
.language-container:hover .language-item {
  left: 0px;
  transition-delay: 0s;
}
.language-item:hover {
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  background-color: #252f10;
  transition: all ease 0.3s;
  position: relative;
  transition-delay: 0s;
}

.language-item.true {
  color: #afff00;
}

.vacancy-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 164px 0px;
}

.vacancy-not-found .holder {
  text-align: center;
  font-family: Supply;
  font-size: 16px;
  line-height: 40px;
  color: #fafafa;
  max-width: 620px;
}

.ua .vacancy-not-found .holder {
  font-family: "Open Sans", sans-serif;
}

/* CHECKBOX */
.checkbox {
  display: flex;
  width: fit-content;
  align-items: center;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  color: rgba(250, 250, 250, 0.8);
  margin-bottom: 8px;
  font-family: Supply;
  line-height: 18px;
  font-weight: normal;
  font-size: 14px;
}
.ua .checkbox {
  font-family: SourceCodePro;
}
/* .checkbox > *{
    display: inline-block;
} */

.applyForm-checkbox {
  margin-bottom: 16px;
}

.checkbox .icon {
  transition: all ease-in-out 0.2s;
  width: 14px;
  height: 14px;
  margin-right: 8px;
  border: 1px solid rgba(250, 250, 250, 0.5);
  flex-shrink: 0;
}
.checkbox:hover {
  transition: all ease-in-out 0.2s;
  color: rgba(250, 250, 250, 1);
}
.checkbox:hover .icon {
  transition: all ease-in-out 0.2s;
  border: 1px solid #fafafa;
}
.checkbox.checked .icon {
  transition: all ease-in-out 0.2s;
  border: 1px solid #afff01;
  background-color: #afff01;
  background-image: url(./assets/images/icons/ic_check.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70%;
}

.checkbox.disabled {
  opacity: 0.7;
}

.applyForm-checkbox-title {
  font-family: Roboto, sans-serif;
}

.checkbox .checkbox-title.widthLimitation {
  max-width: 185px;
}

/* END CHECKBOX */

.jd-filters {
  position: sticky;
  top: 100px;
}
.filter-group {
  margin-bottom: 33px;
}
.show-filters {
  width: 100%;
  padding: 14px 17px 17px 54px;
  background: url(./assets/images/icons/ic_filter.svg),
    linear-gradient(
      309.01deg,
      rgba(1, 1, 1, 0.25) -32.16%,
      rgba(34, 34, 34, 0.25) 127.4%
    ),
    #121212;
  background-repeat: no-repeat;
  background-position: 17px center;
  background-size: 20px 20px;
  cursor: pointer;
  margin-bottom: 24px;
  transition: all ease-in-out 0.2s;
}
.show-filters.true {
  background: url(./assets/images/icons/ic_filter_active.svg),
    linear-gradient(
      309.01deg,
      rgba(1, 1, 1, 0.25) -32.16%,
      rgba(34, 34, 34, 0.25) 127.4%
    ),
    #121212;
  background-repeat: no-repeat;
  background-position: 17px center;
  background-size: 20px 20px;
}
.popup-filter {
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.popup-bottom {
  position: absolute;
  width: 100%;
  bottom: 0px;
  padding: 9px 12px 15px;
  background: linear-gradient(
      309.01deg,
      rgba(1, 1, 1, 0.25) -32.16%,
      rgba(34, 34, 34, 0.25) 127.4%
    ),
    rgba(18, 18, 18, 0.5);
}
.popup-top {
  position: absolute;
  top: 0px;
  width: 100%;
  background: linear-gradient(
      309.01deg,
      rgba(1, 1, 1, 0.25) -32.16%,
      rgba(34, 34, 34, 0.25) 127.4%
    ),
    #121212;
  padding: 24px 19px 24px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Supply;
  font-size: 15px;
  line-height: 19px;
}
.ua .popup-top {
  font-family: SourceCodePro;
}
.popup-top .close {
  background-image: url(./assets/images/close-green.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  width: 18px;
  height: 18px;
  opacity: 1;
}
.popup-top .clear_all {
  color: #afff01;
  margin-right: auto;
  margin-left: 35px;
  cursor: pointer;
  visibility: hidden;
}
.popup-top .clear_all.true {
  visibility: visible;
}

.popup-filter .popup-content {
  width: 100%;
  padding: 16px;
  overflow-y: auto;
  margin-top: 67px;
  margin-bottom: 72px;
}

section.career h4 {
  font-family: Supply;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #fafafa;
  margin-bottom: 18px;
}

.ua section.career h4 {
  font-family: SourceCodePro;
}

/* -------------- TIMELINE -------------- */
.popup.true {
  cursor: pointer;
}

.big-picture {
  font-family: Supply;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  max-width: 1100px;
}
.big-picture .description {
  padding: 20px;
  background-color: #000;
}
.ua .big-picture {
  font-family: SourceCodePro;
}
.big-picture-wrapper {
  position: relative;
}

/* Combine dotes styles from About-PcGallery and news-YearOne*/
.big-picture-wrapper .dotes,
.about-pc-popup .dotes {
  position: absolute;
  bottom: 0;
  width: 100%;
  cursor: initial;
}

.about-pc-popup .dotes {
  z-index: 2;
}

.big-picture-wrapper .dot,
.about-pc-popup .dot {
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(255, 255, 255, 0.25);
  margin: 5px;
  transition: all ease-in-out 0.2s;
}

.big-picture-wrapper .dot.true,
.about-pc-popup .dot.true {
  border: 1px solid rgba(255, 255, 255, 0.8);
  background: rgba(255, 255, 255, 0.8);
  transition: all ease-in-out 0.2s;
}

.popup .prev {
  position: absolute;
  left: 0;
  transform: translateY(-25%);
  padding: 20px 20px 20px 0px;
  z-index: 1001;
}

.popup .prev > div {
  width: 30px;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.25);
  background-image: url(./assets/images/arrow-left-white.svg);
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.7;
  transition: all ease-in-out 0.2s;
}

.popup .next {
  position: absolute;
  right: 0;
  transform: translateY(-25%);
  padding: 20px 0px 20px 20px;
  z-index: 1001;
}

.popup .next > div {
  width: 30px;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.25);
  background-image: url(./assets/images/arrow-right-white.svg);
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.7;
  transition: all ease-in-out 0.2s;
}

/* Arrows false styles on About-PcGallery and news-YearOne */
.popup-new-gallery .left,
.popup-new-gallery .right,
.popup .next.false,
.popup .prev.false {
  transition: opacity ease-in-out 0.2s;
}

.popup .next.false,
.popup .prev.false,
.popup-new-gallery .left.false,
.popup-new-gallery .right.false {
  opacity: 0;
  visibility: hidden;
}

.popup .next:hover > div,
.popup-new-gallery .left.true:hover,
.popup .prev:hover > div,
.popup-new-gallery .right.true:hover {
  opacity: 1;
}

.timeline {
  margin-top: 550px;
  position: relative;
}

.timeline::before {
  content: "";
  background: #afff01;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.timeline::after {
  content: "";
  background: #afff01;
  width: 5px;
  height: 5px;
  left: 50%;
  position: absolute;
  bottom: 0px;
  transform: translateX(-50%);
}

.timeline-item {
  width: 100%;
  margin-bottom: 70px;
  padding-top: 125px;
  margin-top: -380px;
}

.timeline-item:nth-child(even) .timeline-content {
  float: right;
  padding: 40px 30px 10px 30px;
}
.timeline-item:nth-child(even) .timeline-content .date {
  right: auto;
  left: 0;
}
/* RIGHT CARD ARROW
.timeline-item:nth-child(even) .timeline-content::after {
  content: "";
  position: absolute;
  border-style: solid;
  width: 0;
  height: 0;
  top: 30px;
  left: -15px;
  border-width: 10px 15px 10px 0;
  border-color: transparent #121212 transparent transparent;
}
*/
.timeline-item::after {
  content: "";
  display: block;
  clear: both;
}

.timeline-content {
  position: relative;
  width: 45%;
  border-radius: 4px;
  background: linear-gradient(
      309.01deg,
      rgba(1, 1, 1, 0.25) -32.16%,
      rgba(34, 34, 34, 0.25) 127.4%
    ),
    #121212;
}
/* LEFT CARD ARROW
.timeline-content::after {
  content: "";
  position: absolute;
  border-style: solid;
  width: 0;
  height: 0;
  top: 30px;
  right: -15px;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent #121212;
}
*/

/* CARD POINTER ON VERTICAL LINE
.timeline-img {
  width: 10px;
  height: 10px;
  background: #AFFF01;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-top: 35px;
  margin-left: -5px;
}
*/

.timeline-year {
  width: 70px;
  height: 30px;
  background: #afff01;
  position: absolute;
  left: 50%;
  margin-top: -125px;
  margin-left: -35px;
  color: #000;
  font-family: Supply;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
}

.timeline-card {
  padding: 0 !important;
}
.timeline-card p {
  padding: 0 20px;
}
.timeline-item .timeline-img-header {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)),
    url("https://picsum.photos/1000/800/?random") center center no-repeat;
  background-size: cover;
}

.timeline-img-header {
  height: 200px;
  position: relative;
  margin-bottom: 20px;
}
.timeline-img-header h2 {
  color: #ffffff;
  position: absolute;
  bottom: 5px;
  left: 20px;
}

.timeline-card-content {
  padding: 20px 20px 1px 20px;
  font-family: NeueMontreal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(250, 250, 250, 0.8);
}

.timeline-card-content p {
  padding: 0px;
}

.timeline-card-content h3 {
  font-family: Supply;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #afff01;
  margin-bottom: 10px;
}

.ua .timeline-card-content h3 {
  font-family: SourceCodePro;
}

.timeline-card-content .gallery {
  margin-bottom: 20px;
}
.gallery {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.main-picture-frame {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  transition: all ease-in-out 0.2s;
  align-items: flex-start;
}
.main-picture {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  flex-basis: 100%;
  cursor: pointer;
}
.info_picture {
  min-width: 100%;
  max-width: 100%;
  margin-bottom: 34px;
  margin-top: 70px;
}
.thumbnails {
  overflow: hidden;
  margin-top: 13px;
  position: relative;
}
.thumbnails .main-picture-frame {
  transition: all cubic-bezier(0.25, 0.75, 0.5, 1.25) 0.5s;
}
.thumbnails img.main-picture {
  max-width: calc(25% - 10px) !important;
  min-width: calc(25% - 10px) !important;
  margin-left: 13.5px;
  opacity: 0.8;
  cursor: pointer;
}
.thumbnails img.main-picture.active {
  opacity: 1;
}

.slide-prev {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(./assets/images/arrow-left-white.svg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 32px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  left: 0;
  opacity: 0.7;
}
.slide-next {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(./assets/images/arrow-right-white.svg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 32px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  right: 0;
  opacity: 0.7;
  transition: all ease-in-out 0.2s;
}
.slide-prev:hover,
.slide-next:hover {
  opacity: 1;
  transition: all ease-in-out 0.2s;
}
.slide-next.false {
  opacity: 0;
  z-index: -1;
  cursor: unset;
}
.slide-prev.false {
  opacity: 0;
  z-index: -1;
  cursor: unset;
}

/* POPUP FOR ABOUT GALLERY - DESKTOP ONLY */
.about-pc-popup {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
}

.about-pc-gallery-img-wrapper {
  position: absolute;
  opacity: 0;
  z-index: -1;
  transition: all ease 0.2s;
  max-width: 100%;
}

.about-pc-gallery-img-wrapper.true {
  opacity: 1;
  z-index: 1;
  transition: all ease 0.2s;
  max-width: 100%;
}

.about-gallery2 .about-pc-popup .image-home {
  max-width: 100%;
  position: absolute;
  opacity: 0;
  z-index: -1;
  transition: all ease 0.2s;
  max-width: 100%;
}
.about-gallery2 .about-pc-popup .image-home.true {
  opacity: 1;
  z-index: 1;
  transition: all ease 0.2s;
  max-width: 100%;
}
.popup-new-gallery .left.true {
  position: absolute;
  left: 0;
  width: 30px;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.75);
  background-image: url(./assets/images/arrow-left-white.svg);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: center center;
  /* transition: all ease 0.2s; */
  z-index: 2;
  opacity: 0.8;
}
.popup-new-gallery .right.true {
  position: absolute;
  right: 0;
  width: 30px;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.75);
  background-image: url(./assets/images/arrow-right-white.svg);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: center center;
  /* transition: all ease 0.2s; */
  z-index: 2;
  opacity: 0.8;
}
.popup-new-gallery > div:hover {
  opacity: 1;
  transition: all ease 0.2s;
}
.popup-new-gallery > div.false {
  opacity: 0;
  transition: all ease 0.2s;
}
.popup-new-gallery .close {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.75);
  background-image: url(./assets/images/close-white.svg);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all ease 0.2s;
  z-index: 2;
  opacity: 0.75;
  transition: all ease 0.2s;
}
.popup-new-gallery .close:hover {
  opacity: 1 !important;
}

/* .internship{

} */

.inter-wrapper {
  position: relative;
  transform: translateY(0);
  transition: all ease-in-out 1s;
}

.inter-wrapper.true {
  position: relative;
  transform: translateY(-50%);
  transition: all ease-in-out 1s;
}

.inter-wrapper > .p60 > .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.internship-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.internship-cards .card {
  width: calc(25% - 8px);
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 300px;
  background-color: transparent;
}

.internship-cards .card > * {
  position: absolute;
  height: 100%;
}

.internship-cards .card > * > * {
  padding: 15px;
}

.internship-cards .card img {
  margin-top: 45px;
  margin-bottom: 45px;
  padding: 0px;
}
.internship-cards .card.mob {
  min-height: 350px;
}
.internship-cards .card.mob img {
  margin-top: 40px;
  margin-bottom: 20px;
}
.internship-cards .card.mob .title {
  color: #afff01 !important;
}

.internship-cards .card.mob .desc {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.internship-cards .card .content {
  text-align: center;
  width: 100%;
}
.internship-cards .card .hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #121212;
  background-image: linear-gradient(
    309.01deg,
    rgba(1, 1, 1, 0.25) -32.16%,
    rgba(34, 34, 34, 0.25) 127.4%
  );
}

.internship-cards .card .content {
  background-color: #121212;
  background-image: linear-gradient(
    309.01deg,
    rgba(1, 1, 1, 0.25) -32.16%,
    rgba(34, 34, 34, 0.25) 127.4%
  );
}

.internship-cards .card .hover .title {
  font-size: 20px;
  line-height: 27px;
  color: #afff01;
  margin-bottom: 32px;
  font-family: Supply;
}
.internship-cards .card .hover .desc {
  font-size: 16px;
  line-height: 24px;
}

.internship-cards .card .content .title {
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  text-align: center;
  font-family: Supply;
}

.ua .internship-cards .card .content .title {
  font-family: SourceCodePro;
}
.ua .internship-cards .card .hover .title {
  font-family: SourceCodePro;
}
.ua .internship .text_main_content h2 {
  font-family: SourceCodePro;
}

.internship .text_header_content {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 90px;
}
.carousel {
  width: 100%;
}
.internship-cards.hide-tablet .card {
  width: 100%;
}
.internship-cards.hide-mobile.hide-desktop .card {
  width: 100%;
}

.internship .dotes {
  margin-top: 30px;
}

.internship .text_main_content {
  text-align: center;
}

.internship .text_main_content h2 {
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  color: #afff01;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-family: Supply;
}

.internship .text_main_content h4 {
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}

.internship .text_main_content p {
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 24px;
}

.internship .text_main_content p:last-child {
  margin-bottom: 0;
}

.faq.internship {
  margin-bottom: 100px;
}
.faq.internship h1 {
  font-family: Supply;
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  color: #afff01;
  margin-bottom: 35px;
  margin-top: 0px;
}

.faq-container {
  padding-top: 15px;
}
.faq-item {
  margin-bottom: 50px;
  position: relative;
  padding-right: 20px;
}
.faq-item.false::after {
  content: "";
  display: block;
  position: absolute;
  background-image: url(./assets/triangle_top_3x.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 12.73px;
  height: 8px;
  top: 3px;
  right: 0px;
  transition: all ease-in-out 0.2s;
  transform: rotate(-180deg);
  cursor: pointer;
}
.faq-item.true::after {
  content: "";
  display: block;
  position: absolute;
  background-image: url(./assets/triangle_top_3x.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 12.73px;
  height: 8px;
  top: 3px;
  right: 0px;
  transition: all ease-in-out 0.2s;
  transform: rotate(0deg);
  cursor: pointer;
}
.faq-item.false .desc {
  transition: all ease-in-out 0.2s;
  opacity: 0.1;
  overflow: hidden;
}
.faq-item.true .desc {
  transition: all ease-in-out 0.2s;
  opacity: 1;
  overflow: hidden;
}
.faq-item .title {
  transition: all ease-in-out 0.2s;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}
.faq-item .title:hover {
  transition: all ease-in-out 0.2s;
  color: #afff01;
}
.faq-item .desc > div {
  font-size: 16px;
  line-height: 24px;
  color: rgba(250, 250, 250, 0.7);
  margin: 50px 0px 0px;
}

.form-holder-horizontal {
  position: relative !important;
  display: flex;
  align-items: center;
}
.jc-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-white {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.75);
  background-image: url(/static/media/close-white.2ac1d087.svg);
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all ease 0.2s;
  z-index: 1;
  opacity: 0.75;
  transition: all ease 0.2s;
}
.close-white:hover {
  transition: all ease 0.2s;
  opacity: 1;
}

.dark-popup-bg {
  background-color: rgba(0, 0, 0, 1);
}

.hline {
  border-top: 0.1px solid #fafafa;
  height: 0px;
  width: 100%;
  margin: 0 auto;
}

.internship .newsletter-container > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.lever_email_popup {
  padding: 0px 0px;
  opacity: 0.8;
  margin-bottom: 24px;
}

.file-upload-button {
  background: linear-gradient(
      309.01deg,
      rgba(1, 1, 1, 0.25) -32.16%,
      rgba(34, 34, 34, 0.25) 127.4%
    ),
    #121212;
  padding: 14px;
  font-family: Supply;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #afff01;
  opacity: 0.8;
  display: inline-block;
  transition: color 0.2s ease-in-out, border 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  cursor: pointer;
  border: 0.5px solid rgba(175, 255, 1, 0.4);
}
.file-upload-button.true {
  border: solid 0.5px rgba(255, 69, 0, 0.6);
}
.file-upload-button:hover {
  /* background: linear-gradient(309.01deg, rgba(1, 1, 1, 0.35) -32.16%, rgba(34, 34, 34, 0.35) 127.4%), #121212; */
  border: 0.5px solid #afff01;
  opacity: 1;
  transition: all ease-in-out 0.2s;
}
.upload-text-1 {
  margin-top: 0 !important;
  margin-bottom: 4px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
}

.upload-text-1 span {
  font-family: NeueMontreal;
}

.upload-text-2 {
  /* for baseline alignment */
  margin-bottom: 11px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
}
.file-upload-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-box-pack: start;
  -webkit-box-align: start;
  overflow: hidden;
  word-break: break-all;
  background-image: url(./assets/images/icons/document.svg),
    url(./assets/images/icons/close-green.svg);
  background-size: 16px 16px, 14px 14px;
  background-position: 0% 50%, 100% 50%;
  background-repeat: no-repeat;
  color: #afff01;
  padding: 8px 24px 4px;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.8;
  transition: opacity ease-in-out 0.2s;
}
.file-upload-name:hover {
  transition: opacity ease-in-out 0.2s;
  opacity: 1;
}
.section-resume-upload {
  padding-top: 96px;
  padding-bottom: 80px;
  position: relative;
}
.right-part-upload {
  padding-bottom: 23px;
}

.no-file-error-msg {
  font-family: Supply;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #fc0f0f;
  visibility: hidden;
  opacity: 0;
  transition: 0.7s;
  transform: translateY(10%);
}

.no-file-error-msg.true {
  visibility: visible;
  opacity: 0.9;
  transform: translateY(0);
}

.success-sent-message {
  position: absolute !important;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all ease-in-out 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.success-sent-message.true {
  position: absolute !important;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all ease-in-out 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.chackmark-green-big {
  width: 55px;
  height: 45px;
  background-image: url(./assets/images/checkmark-green-big.svg);
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 24px;
}
.success-sent-message .text1 {
  font-family: Supply;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #afff01;
  margin-bottom: 16px;
}
.success-sent-message .text2 {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fafafa;
}

.loader-holder {
  position: absolute !important;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all ease-in-out 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-holder.true {
  position: absolute !important;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  opacity: 1;
  transition: all ease-in-out 0.2s;
}

.loader {
  color: #ffffff;
  font-size: 20px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.section-resume-upload .vacancy-additional {
  padding-bottom: 80px;
}

.holder404 {
  padding-top: 0px;
  padding-bottom: 100px;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative !important;
}

.holder404.false .sh {
  opacity: 0;
  visibility: hidden;
  transition: all ease-in 0.5s;
}
.holder404.true .sh {
  opacity: 1;
  visibility: visible;
  transition: all ease-in 0.5s;
}
.holder404.true .g-loader {
  visibility: hidden;
}

.page404 {
  font-family: Supply;
  font-weight: 500;
  font-size: 140px;
  line-height: 168px;
  color: #afff01;
  background-color: #000;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  animation: glitch 1s linear infinite;
  position: relative;
}
.page404-1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 20px;
}
.page404-2 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 40px;
}

@keyframes glitch {
  2%,
  64% {
    transform: translate(2px, 0) skew(0deg);
  }
  4%,
  60% {
    transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    transform: translate(0, 0) skew(5deg);
  }
}

.page404:before,
.page404:after {
  content: attr(title);
  position: absolute;
  left: 0;
}

.page404:before {
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop {
  2%,
  64% {
    transform: translate(2px, -2px);
  }
  4%,
  60% {
    transform: translate(-2px, 2px);
  }
  62% {
    transform: translate(13px, -1px) skew(-13deg);
  }
}

.page404:after {
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom {
  2%,
  64% {
    transform: translate(-2px, 0);
  }
  4%,
  60% {
    transform: translate(-2px, 0);
  }
  62% {
    transform: translate(-22px, 5px) skew(21deg);
  }
}

.g-loader {
  width: 100%;
  height: 100%;
  position: absolute;
}
.loader-logo {
  background-image: url(./assets/images/g_loader.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 71px 50px;
  width: 110px;
  height: 110px;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  margin-left: -55px;
  margin-top: -55px;
}

.loader-line-mask {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 55px;
  height: 120px;
  margin-left: -55px;
  margin-top: -55px;
  overflow: hidden;
  transform-origin: 55px 55px;
  -webkit-mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
  animation: rotate 1.2s infinite linear;
}

.loader-line-mask .loader-line {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px rgba(175, 255, 1, 0.8);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.disclaimer {
  font-size: 10px;
  line-height: 14px;
  margin-top: 10px;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in 0.2s;
  transform: translate(-2px, 15px);
}
.section-resume-upload .disclaimer {
  margin-top: 0;
  margin-bottom: 15px;
}
/*.vacancy-page .disclaimer{*/
/*    margin-top: -10px;*/
/*}*/
.disclaimer.d350 {
  width: 100%;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}
.disclaimer.true {
  opacity: 0.8;
  visibility: visible;
  transition: all ease-in 0.2s;
  transform: translate(0px, 0px);
}
.disclaimer p {
  margin-bottom: 0;
}
.contact-us .disclaimer {
  margin-top: 0;
  margin-bottom: 5px;
}

.logo-col {
  flex-direction: column;
}

.external_resource {
  background: rgba(16, 16, 16, 0.8);
  padding: 18px;
  font-family: Supply;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  position: absolute;
  bottom: 10px;
  left: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
/* ---------------------------------------------------------------------------- */

@media screen and (max-width: 768px) {
  .news-title-block {
    flex-direction: column;
    margin-bottom: 25px;
  }
  .timeline::before {
    left: 30px;
  }
  .timeline::after {
    left: 30px;
  }
  .timeline .timeline-img {
    left: 50px;
  }

  .timeline-year {
    left: 30px;
  }

  .timeline .timeline-content {
    max-width: 100%;
    width: auto;
    margin-left: 70px;
  }
  .timeline .timeline-item:nth-child(even) .timeline-content {
    float: none;
  }
  .timeline {
    position: relative;
    margin-top: 0px;
  }
  .timeline-item {
    margin-top: 0px;
  }
  .last {
    order: 1;
  }
  /* RIGHT CARD ARROW
  .timeline .timeline-item:nth-child(odd) .timeline-content::after {
    content: "";
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 30px;
    left: -15px;
    border-width: 10px 15px 10px 0;
    border-color: transparent #f5f5f5 transparent transparent;
  }
  */

  .no-file-error-msg {
    transform: translateX(-100%);
    transition: margin ease-out 0.5s, transform ease-out 0.7s,
      opacity ease-out 0.15s, visibility ease-out 0.2s;
  }

  .no-file-error-msg.true {
    margin-bottom: 17px;
    visibility: visible;
    opacity: 0.9;
    transform: translateX(0);
  }
}

@media screen and (max-width: 545px) {
  .timeline::before {
    left: 0px;
  }
  .timeline::after {
    left: 0px;
  }
  .timeline .timeline-img {
    left: 10px;
  }

  .timeline-year {
    left: 0px;
    margin-left: 0px;
  }

  .timeline .timeline-content {
    max-width: 100%;
    width: auto;
    margin-left: 15px;
  }
  .timeline .timeline-item:nth-child(even) .timeline-content {
    float: none;
  }
  .timeline {
    position: relative;
    margin-top: 0px;
  }
  .timeline-item {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  /* RIGHT CARD ARROW
    .timeline .timeline-item:nth-child(odd) .timeline-content::after {
      content: "";
      position: absolute;
      border-style: solid;
      width: 0;
      height: 0;
      top: 30px;
      left: -15px;
      border-width: 10px 15px 10px 0;
      border-color: transparent #f5f5f5 transparent transparent;
    }
    */
}

/* END TIMELINE */

/* RESPONSIVE LAYOUT */
@media (min-width: 1000px) and (max-width: 1405px) {
  .p60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 993px) {
  .hide-desktop {
    position: absolute !important;
    z-index: -100;
    visibility: hidden;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
}

@media (min-width: 1200px) {
  #root > section.our-game-bg {
    min-height: 800px;
  }
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1280px;
  }
}

@media (min-width: 992px) {
  .show-filters {
    display: none;
  }
  .about-gallery2 {
    display: none;
  }
  #root > section {
    padding-left: 60px;
    padding-right: 60px;
  }
  #root > section.our-game-bg {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .first-column .share-buttons {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1500px) {
  .vacancies-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .vacancy-card-holder {
    min-height: 180px;
  }

  .right-column .vacancy-card {
    min-height: 180px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  #root > section.our-game-bg {
    min-height: 600px;
  }
  .news-grid {
    column-gap: 40px;
  }
  .vacancy-card-holder {
    min-height: 200px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .hide-tablet {
    position: absolute !important;
    z-index: -100;
    visibility: hidden;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  .about-gallery2 .row > div {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 992px) {
  /* 575 */
  .vacancy-page {
    padding-bottom: 96px;
  }
  .header {
    padding: 14px 0px;
  }
  .popup-is-opened {
    padding-right: 0px;
  }
  .position-fixed {
    position: fixed;
  }
  .sticky-blcok {
    position: sticky;
    top: 0px;
    z-index: 1;
  }
  .sticky-blcok.show-true {
    z-index: 200;
  }
  .sticky-blcok.true {
    position: sticky;
    top: -1px;
  }
  .sticky-blcok.true.show-true {
    z-index: 3;
  }
  .sticky-blcok.true .show-filters {
    position: relative;
    background-color: #000;
    transition: all ease-in-out 0.2s;
    bottom: 0px;
  }
  .sticky-blcok.true .show-filters.up-true {
    position: relative;
    background-color: #000;
    transition: all ease-in-out 0.2s;
    bottom: -65px;
  }
  #root > section.our-game-bg {
    min-height: 500px;
  }
  .right-column .share-buttons {
    display: none;
  }
  .language-list {
    overflow-x: hidden;
  }
  .language-container {
    margin-left: 0;
  }
  .news-grid {
    column-gap: 40px;
  }
  .rslide.cols4 {
    grid-template-columns: 1fr 1fr !important;
  }
  .leadership-content {
    grid-template-columns: 1fr 1fr;
  }
  .about-cards .flip-box:hover .flip-box-inner {
    transform: none;
    transition: none;
  }

  .about-cards .content .desc {
    display: block;
  }
  .about-cards .content .title {
    font-family: Supply;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #afff00;
    top: 0;
  }
  .ua .about-cards .content .title {
    font-family: SourceCodePro;
  }
  .about-cards .card .content {
    padding: 20px 16px 56px;
    justify-content: flex-end;
    position: relative;
  }
  .about-cards .card .content::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      309.01deg,
      rgba(1, 1, 1, 0.8) -32.16%,
      rgba(34, 34, 34, 0.8) 127.4%
    );
  }
  .footer .copyright span {
    display: inline;
  }
  .about-cards .card .content {
    background-size: cover;
  }
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: unset;
  }

  .icons.mobile {
    display: flex !important;
  }
  .overflowHidden {
    overflow: hidden;
  }
  .popup {
    display: none;
  }
  .desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
  .about-gallery {
    display: none;
  }
  .header2-logo img {
    max-width: 100%;
  }
  .mob-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .menu-content {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    top: -100vh;
    height: 100vh;
    left: 0;
    right: 0;
    opacity: 1;
    z-index: 100;
    transition: all ease-out 0.4s;
  }

  .menu-content.true {
    top: 0;
    opacity: 1;
    transition: all ease-out 0.4s;
  }
  .menu-content .left {
    padding: 55px 0px 15px;
    background-color: #1c1b1b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    width: 100%;
  }
  .menu-content .left .logo-footer {
    display: inline-block;
  }

  .burger {
    width: 32px;
    height: 32px;
    background-size: contain;
    background-image: url(./assets/images/burger@2x.png);
  }

  .menu-content .burger-close {
    position: absolute;
    top: 20px;
    right: 10px;
    width: 26px;
    height: 18px;
    margin: 5px;
    min-height: 18px;
    min-width: 18px;
    min-height: 18px;
    background-image: url(./assets/images/close-green.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    cursor: pointer;
    z-index: 100;
  }

  .menu-content .left .logo-footer {
    height: 36px;
  }

  .menu-content .menu-items {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-self: center;
    height: 100%;
    justify-content: space-evenly;
    max-height: 400px;
    width: 100%;
  }

  .react-reveal.iconsWhite {
    justify-content: center;
  }

  .element-visible {
    display: flex;
    justify-content: center;
  }
  .submenu-container {
    position: relative;
  }
  .submenu-container .sun-menu {
    background-color: #000;
  }

  .submenu-container .sun-menu a {
    text-transform: none !important;
  }

  .submenu-container.false .sun-menu {
    height: 0px;
    overflow: hidden;
    transition: all ease-in-out 0.2s;
  }
  .submenu-container.false .arrow-top {
    width: 15px;
    height: 12.5px;
    background-image: url(./assets/images/arrow_top.svg);
    background-position: center;
    background-repeat: no-repeat;
    transition: all ease-in-out 0.2s;
    transform: rotate(-180deg);
    display: inline-block;
    position: absolute;
    margin-left: 50px;
    top: 12px;
  }
  .submenu-container.true .arrow-top {
    width: 15px;
    height: 12.5px;
    background-image: url(./assets/images/arrow_top.svg);
    background-position: center;
    background-repeat: no-repeat;
    transition: all ease-in-out 0.2s;
    display: inline-block;
    position: absolute;
    margin-left: 50px;
    top: 12px;
  }
  .submenu-container.true .sun-menu {
    overflow: hidden;
    margin: 5px 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: all ease-in-out 0.2s;
  }

  .menu-content .menu-items a {
    margin: 5px 0px;
    font-family: Supply;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    color: rgba(255, 250, 250, 0.7);
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    width: 100%;
    position: relative;
  }
  .ua .menu-content .menu-items a {
    font-family: SourceCodePro;
  }
  .menu-content .menu-items a:hover {
    color: rgba(255, 250, 250, 1);
  }
  .menu-content .menu-items a span {
    position: absolute;
    margin-left: 10px;
    top: 6px;
    font-size: 13px;
    font-weight: 500px;
    line-height: 19px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .menu-content .icons-light {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-bottom: 28px;
  }
  .menu-content .iconsWhite {
    margin-top: 20px;
  }
  .menu-content .iconsWhite > * {
    margin: 12px !important;
    width: 25px !important;
    display: block;
  }

  .menu-content .icons-light a {
    width: 40px;
    height: 40px;
    margin: 20px 0px;
  }
  .mobile-menu-logo {
    top: 14px;
    left: 15px;
    position: absolute;
  }
  /* HEADER 2 */
  .header2 {
    padding-top: 168px;
    padding-bottom: 104px;
  }
  .header2-text h1 {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    margin-left: auto;
    margin-right: auto;
  }
  /*
  .text-reveal > *{
    display: inline-block;
    position: relative;
    transform: scaleY(0.0001);
    transition: all ease-out 0.2s;
    transform-origin: 0 0;
  }

  .text-reveal.true > *{
    display: inline-block;
    position: relative;
    transform: scaleY(1);
    transition: all ease-in-out .3s;
    transform-origin: 0 0;
  }

  .text-reveal > *:after{
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    right: -5px;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    transform: matrix(1, 0, 0, 1, 0, 0);
    transform-origin: 100% 100%;
    z-index: 2;
    transition: all ease-out 0.s;
  }

  .text-reveal.true> *:after{
    background: #FFFFFF;
    transform: matrix(1, 0, 0, 0.0001, 0, 0);
    transform-origin: 100% 100%;
    z-index: 2;
    transition: all ease-in-out 0.6s;
  }
*/
  /* FOOTER */

  .footer .logo {
    margin-bottom: 48px;
  }

  .footer .icons {
    justify-content: flex-start;
  }
  .footer .icons a,
  .icons-light a {
    margin: 16px 8px;
  }

  .footer .subscribe input[type="text"] {
    width: 100%;
  }
  .button.subscribe {
    position: absolute;
  }
  /* ABOUT BLOCK */
  .about-cards .card {
    width: 100% !important;
    margin-right: 0px;
    height: 528px;
    position: relative;
    overflow: hidden;
  }
  .about-cards .card .hover {
    padding: 20px 16px;
    z-index: 1;
    transition: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 56px;
    height: 100%;
  }
  .about-cards .desc {
    opacity: 1;
    color: #fafafa;
    /* font-family: NeueMontreal; */
    font-size: 16px;
    line-height: 1.5;
    position: relative;
  }

  .about-cards .carousel {
    width: 100%;
  }

  /* VACANCY PAGE */
  .vacancies-grid {
    grid-template-columns: 1fr 1fr;
  }
  .vacancy-search {
    margin-top: 40px;
    grid-area: 0/2;
  }
  /* LEADERSHIP */

  .leader-card .poster {
    display: flex;
    flex-direction: column;
    border: none;
  }
  .leader-card .poster .leader-hover {
    border-top: 4px solid #afff01;
    position: static;
    opacity: 1;
    background-color: transparent;
    color: #afff01;
    padding: 8px 0px;
  }
  .popoup-content {
    border: none;
    padding: 64px 0px 32px;
    overflow-y: auto;
  }
  .popoup-content > .row {
    margin: 0px;
  }
  .popoup-content > .row > div {
    padding: 0px;
  }
  .popoup-content > .row > div.rightPanel {
    padding-left: 15px;
    padding-right: 15px;
  }

  .popoup-content .close {
    left: 15px;
    opacity: 1;
  }
  .popoup-content .icons {
    margin-bottom: 32px;
  }
  .popoup-content .icons a {
    margin: 0 16px 0 0;
  }

  .popoup-content .title {
    font-size: 20px;
    margin-bottom: 4px;
  }

  .popoup-content .role {
    font-size: 16px;
  }
  .leadership-content {
    margin-bottom: 32px;
  }

  .rslide > div {
    margin-bottom: 24px;
  }
  /* .vacancy-page .right-column{

  } */
  .vacancy-page .right-column .button.link-button {
    display: none;
  }

  .contact-links {
    margin-top: 40px;
  }

  .logo-col {
    order: 0;
  }
  .subscribe-col {
    order: 2;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .lang-col {
    order: 1;
  }
  .lang-col > div {
    margin-right: 0px;
    margin-left: auto;
  }
  .icons-col {
    order: 3;
    justify-content: flex-end !important;
  }
  .copyright-col {
    order: 4;
  }
}

@media (max-width: 767px) {
  .contact-us {
    margin-bottom: 96px;
  }
  .career {
    padding-bottom: 96px;
  }
  section.latest-news {
    margin-bottom: 46px;
  }
  #root > section.our-game-bg {
    min-height: 400px;
  }
  section.article {
    margin-bottom: 96px;
  }
  section.newsletter {
    margin-bottom: 96px;
  }
  .vacancy-control {
    display: grid;
    grid-template-columns: 1fr !important;
  }
  .vacancy-search {
    margin-bottom: 24px;
    grid-area: auto;
  }
  .about-gallery2 .row > div {
    padding-left: 0px;
    padding-right: 0px;
  }
  .cookies-popup .text {
    margin-bottom: 24px;
  }
  .vacancies-grid {
    grid-template-columns: 1fr;
  }
  .hide-mobile {
    position: absolute !important;
    z-index: -100;
    visibility: hidden;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  .news-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 574px) {
  #root > section.our-game-bg {
    min-height: 300px;
  }
  .footer {
  }
  .footer .container .row > div {
    justify-content: center !important;
    display: flex;
  }
  .footer .container .row > div > div {
    margin-left: auto;
    margin-right: auto;
  }

  .logo-col {
    align-items: center !important;
  }
  .subscribe-col {
    padding-bottom: 48px;
  }
  .lang-col {
    padding-bottom: 48px;
  }
  .lang-col > div {
  }
  .icons-col {
  }
  .copyright-col {
    margin-bottom: 0px !important;
  }
  .internship .text_main_content h2 {
    font-size: 20px;
  }
  .internship .text_main_content h4,
  .internship .text_main_content p {
    font-size: 14px;
  }
  .internship .text_header_content {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 545px) {
  .share-buttons {
    width: 100%;
  }
  #root > section.our-game-bg {
    min-height: 200px;
  }
  .leadership-content {
    grid-template-columns: 1fr;
  }
  .rslide.cols4 {
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
  section.about .row > div h2,
  section.leadership .row > div h2,
  section.latest-news .row > div h2 {
    font-size: 32px;
    margin-bottom: 35px;
  }
  .button {
    width: 100%;
  }
  .linkedin-button {
    max-width: 100%;
  }
  .header2 {
    background-size: 400%;
    background-position: 62% 39%;
  }
  .radoigroup-container .radiobutton {
    width: 100% !important;
  }
  .radoigroup-container {
    margin-bottom: 40px !important;
  }
  .home-perks {
    grid-template-columns: 1fr;
  }
  .news-grid {
    grid-template-columns: 1fr;
  }
  section.article h1 {
    font-size: 30px;
    line-height: 1.18;
  }
  .rslide.cols4 {
    display: grid !important;
    grid-template-columns: 1fr !important;
  }
}

@media (max-width: 380px) {
  a.more-articles {
    font-size: 12px;
  }
}

/*Main Page's 'about Gunzilla' cards vertical text
overflowing on small desktop resolutions fix */
@media (min-width: 992px) and (max-width: 1070px) {
  .about-cards .card.flip-box {
    height: 520px;
  }

  .about-cards .card.flip-box .hover {
    padding-bottom: 32px;
    justify-content: center;
  }
}

/*NEW STYLES*/
/*Header-2 slider*/
.header2-slider {
  margin-top: -140px;
  margin-bottom: 96px;
  position: relative;
}

/*.header2-slider .swiper-pagination {*/
/*  position: absolute;*/
/*  bottom: 0 !important;*/
/*  left: 50% !important;*/
/*  transform: translateX(-50%);*/
/*}*/

.header2-slider .header2 {
  margin-top: 65px;
  margin-bottom: 0;
  padding-top: 225px;
  padding-bottom: 132px;
}

.header2-slider .swiper-pagination {
  bottom: 12px !important;
}

.header2-slider .swiper-pagination-bullet {
  background: transparent;
  border: 1px solid #fafafa;
  opacity: 1;
}

.header2-slider .swiper-pagination-bullet-active {
  background: rgba(175, 255, 1, 1);
  border: transparent;
}

.header2-slider .header2--year-two,
.header2-slider .otgPreviewSection {
  margin-top: 0;
  margin-bottom: 0;
}

.header2-slider .otgPreviewSection {
  height: 703px;
  background-size: cover;
  background-position: center;
}

.header2-slider .otgPreviewSection .platforms-icons {
  display: none;
}

@media (max-width: 992px) {
  .header2-slider .header2 {
    padding-top: 168px;
    padding-bottom: 104px;
  }

  .header2-slider .col-xl-6.col-md-12.col-lg-9 {
    display: flex;
    justify-content: center;
  }

  .header2-slider .header2-logo img {
    max-width: 620px;
  }
}

@media (max-width: 545px) {
  .header2 {
    background-size: 400%;
    background-position: 62% 39%;
  }
}

@media (min-width: 992px) {
  .header2-slider .header2 {
    padding-left: 60px;
    padding-right: 60px;
  }
}

/*header2 'YEAR 2 SLIDE START'*/
.header2--year-two {
  /*margin-top: -140px;*/
  margin-bottom: 54px;
  width: 100%;
  height: 703px;
  display: flex;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%),
    #2d2d2d;
}

.header2--year-two .leftSideBackgroundHandler {
  width: 44%;
  height: 100%;
  background-image: url("./assets/images/year-two/left-side-bg-desktop.png");
  background-size: 100% 100%;
}

.header2--year-two .leftSideContentHandler {
  width: 100%;
  position: absolute;
  padding: 155px 75px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header2--year-two .leftSideContentHandler .title {
  font-family: "Supply", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  color: #ffffff;
  /*opacity: 0.9;*/
  opacity: 0;
  transform: translateY(-40%);
}

.header2--year-two .leftSideContentHandler .highlightedTitle {
  margin-top: 50px;
  width: 27.357vw;
  height: 16vw;
  background-image: url("./assets/images/year-two/left-side-title.svg");
  background-size: 100% 100%;
  /*opacity: 0.95;*/
  opacity: 0;
  transform: translateY(-40%);
}

.header2--year-two .leftSideContentHandler .linkWrapperOfBtn {
  opacity: 0;
  transform: translateY(40%);
}

.header2--year-two .leftSideContentHandler .redirect-button {
  margin-top: 52px;
  width: 295px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Supply, sans-serif;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid rgba(175, 255, 1, 0.4);
  color: rgba(175, 255, 1, 1);
  background-color: rgba(175, 255, 1, 0.18);
  box-shadow: inset 0 0 0 0 #afff01;
  transition: all ease-in 0.07s;
  cursor: pointer;
}

.header2--year-two .leftSideContentHandler .redirect-button:hover {
  box-shadow: inset 0 0 0 2em #afff01, 0 0 16px 0 rgba(175, 255, 1, 0.4);
  opacity: 0.9;
  color: #000;
}

.header2--year-two .leftSideContentHandler .title.animated {
  animation: headerLeftSideContentTranslateTop 0.9s ease-out 0.2s forwards;
}

.header2--year-two .leftSideContentHandler .highlightedTitle.animated {
  animation: headerLeftSideContentTranslateTop-secondary 0.9s ease-out 0.3s
    forwards;
}

.header2--year-two .leftSideContentHandler .linkWrapperOfBtn.animated {
  animation: headerLeftSideContentTranslateBottom 0.9s ease-out 0.4s forwards;
}

@keyframes headerLeftSideContentTranslateTop {
  from {
    opacity: 0;
    transform: translateY(-40%);
  }
  to {
    opacity: 0.95;
    transform: translateY(0);
  }
}

@keyframes headerLeftSideContentTranslateTop-secondary {
  from {
    opacity: 0;
    transform: translateY(-20%);
  }
  to {
    opacity: 0.95;
    transform: translateY(0);
  }
}

@keyframes headerLeftSideContentTranslateBottom {
  from {
    opacity: 0;
    transform: translateY(40%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.header2--year-two .rightSide {
  width: 56%;
  padding-top: 50px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.header2--year-two .rightSide .banner {
  /*width: 94%;*/
  /*height: calc(94% * 0.77887);*/
  margin-left: 1.85vw;
  width: 48.4vw;
  height: calc(48.4vw * 0.77887);
  background-image: url("./assets/images/year-two/right-side-banner-desktop.png");
  background-size: 100% 100%;
  opacity: 0;
  z-index: 1;
}

.header2--year-two .rightSide .banner.animated {
  animation: headerRightSideBannerTranslate 0.7s ease-out forwards 0.2s;
}

@keyframes headerRightSideBannerTranslate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*max-width setups*/
@media (max-width: 1200px) {
  .header2--year-two .leftSideContentHandler {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 960px;
    left: 0;
    right: 0;
  }

  .header2--year-two .rightSide .banner {
    width: 48.4vw;
    height: calc(48.4vw * 0.77887);
    /*width: 516px;*/
    /*height: 438px;*/
  }

  @keyframes headerLeftSideContentOpacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@media (max-width: 1080px) {
  .header2--year-two .leftSideContentHandler {
    max-width: 100%;
    padding-left: 75px;
    padding-right: 75px;
  }

  .header2--year-two .rightSide {
    padding-top: 58px;
  }

  .header2--year-two .rightSide .banner {
    width: 47vw;
    height: calc(47vw * 0.77887);
  }
}

@media (max-width: 992px) {
  /*.header2--year-two {*/
  /*  height: 600px;*/
  /*}*/

  .header2--year-two .leftSideBackgroundHandler {
    width: 46.875vw;
    background-image: url("./assets/images/year-two/left-side-bg-tablet.png");
  }

  .header2--year-two .leftSideContentHandler {
    max-width: 100%;
    padding: 193px 30px 0;
  }

  .header2--year-two .leftSideContentHandler .title {
    font-family: "Supply", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #ffffff;
    opacity: 0;
  }

  .header2--year-two .leftSideContentHandler .highlightedTitle {
    width: 250px;
    height: 145px;
  }

  .header2--year-two .leftSideContentHandler .redirect-button {
    width: 33.72vw;
    max-width: 295px;
  }

  .header2--year-two .leftSideContentHandler .redirect-button:hover {
    box-shadow: none;
    opacity: 1;
    color: rgba(175, 255, 1, 1);
  }

  .header2--year-two .rightSide {
    padding-top: 35px;
    position: relative;
  }

  .header2--year-two .rightSide:after {
    content: "";
    position: absolute;
    top: 12.5%;
    right: -2%;
    width: calc(0.1394 * 550px);
    height: 550px;
    background-image: url("./assets/images/year-two/right-side-bg-vertical-text.svg");
    background-size: 100% 100%;
    opacity: 0.6;
  }

  .header2--year-two .rightSide .banner {
    margin-left: 0.85vw;
    width: 50.1vw;
    height: calc(50.1vw * 0.975);
    background-image: url("./assets/images/year-two/right-side-banner-tablet.png");
  }
}

@media (max-width: 768px) {
  .header2--year-two {
    /*height: 480px;*/
    display: flex;
    flex-direction: column;
  }

  .header2--year-two .leftSideBackgroundHandler {
    width: 100%;
    /*height: 480px;*/
    background-image: url("./assets/images/year-two/left-side-bg-mobile.jpg");
  }
  .header2--year-two .leftSideContentHandler {
    /*padding: 100px 15px 0;*/
    height: 100%;
    padding: 0 15px;

    align-items: center;
  }

  .header2--year-two .leftSideContentHandler .title {
    margin-left: auto;
    margin-right: auto;
  }

  .header2--year-two .leftSideContentHandler .highlightedTitle {
    width: 250px;
    height: calc(250px * 0.582);
    margin: 42px auto 0;
  }

  .header2--year-two .leftSideContentHandler .redirect-button {
    margin: 42px auto 0;
    width: 265px;
  }

  .header2--year-two .rightSide {
    /*width: 100%;*/
    /*height: 60px;*/
    /*padding-top: 0;*/
    /*overflow-x: initial;*/
    display: none;
  }

  .header2--year-two .rightSide:after {
    display: none;
  }

  .header2--year-two .rightSide .banner {
    display: none;
  }
}

@media (max-width: 620px) {
  .header2--year-two .leftSideContentHandler .highlightedTitle {
    /*width: 265px;*/
    /*height: calc(265px * 0.582);*/
  }
}

@media (max-width: 460px) {
  .header2--year-two .rightSide {
    overflow: hidden;
  }
}

@media (max-width: 380px) {
  .header2--year-two .leftSideContentHandler .highlightedTitle {
    /*width: 210px;*/
    /*height: calc(210px * 0.582);*/
  }

  .header2--year-two .rightSide {
    overflow: hidden;
  }

  .header2--year-two .leftSideContentHandler {
    /*padding: 120px 15px 0;*/
  }
}

/*min width setups*/
@media (min-width: 1430px) {
  .header2--year-two .leftSideBackgroundHandler {
    background-image: url("./assets/images/year-two/left-side-bg-desktop-wide.png");
  }

  .header2--year-two .leftSideContentHandler {
    max-width: 1280px;
    margin: 0 auto;
    left: 0;
    right: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  .header2--year-two .leftSideContentHandler .highlightedTitle {
    max-width: 383px;
    max-height: 224px;
  }

  .header2--year-two .rightSide {
    padding-top: 50px;
  }

  .header2--year-two .rightSide .banner {
    margin-left: 1.5vw;
    /*width: 45vw;*/
    /*height: calc(0.516 * 47vw);*/
    width: 693px;
    height: 382px;
    /*max-width: 693px;*/
    /*max-height: 429px;*/
    background-image: url("./assets/images/year-two/right-side-banner-desktop-wide.png");
  }

  .header2--year-two .rightSide:after {
    content: "";
    width: calc(0.1394 * 810px);
    height: 810px;
    position: absolute;
    top: -7%;
    right: -30px;
    background-image: url("./assets/images/year-two/right-side-bg-vertical-text.svg");
    background-size: 100% 100%;
    opacity: 0.6;
  }
}

@media (min-width: 1600px) {
  .header2--year-two .rightSide .banner {
    margin-left: 1.8vw;
  }
}

@media (min-width: 1750px) {
  .header2--year-two .rightSide .banner {
    margin-left: calc(2.5vw);
  }
}

@media (min-width: 2000px) {
  .header2--year-two .rightSide .banner {
    margin-left: calc(2.8vw);
  }
}
/*header2 'YEAR 2 SLIDE END'*/

/*Lever if absence error notification on 'Career page'*/
.leverIfAbsenceNotificationError {
  margin-bottom: 96px;
  color: #fc0f0f;
}

.leverIfAbsenceNotificationError span {
  color: #ffffff;
}

.leverIfAbsenceNotificationError span span {
  color: rgba(175, 255, 0, 1);
}

/*SMOOTH SCROLL PROP*/
html {
  scroll-behavior: smooth !important;
}

/*colored red text in articles*/
cite {
  color: #afff00;
  font-style: normal;
}

/*Updating header menu styles cause of increasing horizontal length of nav items*/
@media (min-width: 992px) and (max-width: 1200px) {
  .header-nav-menu-items {
    max-width: 71.8%;
  }

  .header .header-nav-menu-items ul li {
    margin-left: 18px;
    margin-right: 18px;
  }

  .header-languages-icon {
    margin-left: auto;
    max-width: 4%;
  }
}

/*Header nav links*/
.language-item.lditem .external-link {
  color: rgba(255, 255, 255, 0.7);
}

.language-item.lditem .external-link:hover {
  color: #ffffff;
}

.language-item.lditem.headerDdMenuItem {
  position: relative;
}

.language-item.lditem.headerDdMenuItem a {
  display: block;
}

.language-item.lditem.headerDdMenuItem a:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

/*additional classes for nav items visibility.
Adding a shadow effect to white text elements
(as well as the language icon)
that are on a white background
 */

.withBoxShadowForVisibility {
  box-shadow: 0 0 12px 4px rgba(125, 124, 124, 0.4);
  border-radius: 50%;
}

.withTextShadowForVisibility {
  text-shadow: 1px -1px 3px rgba(0, 0, 0, 0.7);
}

.withTextLighterForVisibility a {
  color: rgba(255, 255, 255, 0.85);
}

.lditem .vc {
  text-shadow: none;
}

.language-icon.lditem {
  flex-shrink: 0;
}

/* new button styles */
.gamescom2022Slide .redirect-button,
.otgPreviewSection .redirect-button {
  /* margin-top: 60px; */
  width: 295px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Supply, sans-serif;
  font-size: 14px;
  font-weight: 700;
  border: 0.5px solid rgba(175, 255, 1, 0.4);
  color: rgba(175, 255, 1, 1);
  background: rgba(175, 255, 1, 0.12);
  box-shadow: inset 0 0 0 0 #afff01;
  transition: all ease-in 0.07s;
  cursor: pointer;

  /*for fadeIn animation boilerplate*/
  opacity: 0;
  transform: translateY(70%);
}

/*hoverOn - basic class while without fade-in transition or add after fade-in*/
.gamescom2022Slide .redirect-button:hover,
.otgPreviewSection .hoverOn.redirect-button:hover {
  box-shadow: inset 0 0 0 2em #afff01, 0 0 16px 0 rgba(175, 255, 1, 0.4);
  opacity: 0.9;
  color: #000;
}

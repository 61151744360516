.radoigroup-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    margin-bottom: 0px;
    margin-top: 8px;
}
.radoigroup-container .radiobutton{
    cursor: pointer;
    width: calc(50% - 8px);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    margin: 0px 0px 16px 0px;
    border: solid 0.5px rgba(175, 255, 1, 0.4);
    background-color: #000000;
    transition: all ease-in 0.2s;
}

.radoigroup-container .circle{
    border-radius: 50%;
    margin-right: 8px;
    border: solid 1px #979797;
    min-width: 16px;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    transition: all ease-in 0.2s;
}

.radoigroup-container .label{
    font-family: Supply;
    font-size: 14px;
    line-height: 1.43;
    color: #fafafa;
    transition: all ease-in 0.2s;
}

.ua .radoigroup-container .label{
    font-family: SourceCodePro;
}


/* HOVER BUTTON */
.radoigroup-container .radiobutton:hover {
    background-color: rgba(175, 255, 1, 0.3);
}

/* ACTIVE BUTTON */
.radoigroup-container .radiobutton.selected {
    background-color: #afff01;
    transition: all ease-out 0.2s;
}

.radoigroup-container .radiobutton.selected .circle {
    background-color: #fafafa;
    border: solid 3px #000000;
    transition: all ease-out 0.2s;
}

.radoigroup-container .radiobutton.selected .label {
    color: #000000;
    transition: all ease-out 0.2s;
}
.otgPreviewSection {
  margin-top: -140px;
  padding: 0 60px;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
      rgba(0, 0, 0, 0.05) 100%,
      rgba(0, 0, 0, 0.05) 100%
    ),
    url("../../src/assets/images/game-page/OTG-preview-section\(desktop-tablet\).png");
  /*other bg props assignment by context(by 'current page' settings with forwarded class)*/
  max-width: 100vw;
}

.otgPreviewSection .content {
  margin: 0 auto;
  width: 100%;
  /*padding-top: 140px;*/
  padding-top: 64px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*Wrapper for handle prop of parallax translation*/
.otgPreviewSection .content div {
  transition: 0.3s transform ease-out, 0.07s box-shadow ease-in,
    0.07s opacity ease-in, 0.07s color ease-in;
}

.otgPreviewSection .content .column-wrapper {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.otgPreviewSection .otgLogo {
  width: 500px;
  height: 500px;
  margin-right: -8%;
  background: url("../../src/assets/images/game-page/OTG-logo.svg");
  background-size: 100% 100%;

  /*for fadeIn animation boilerplate*/
  opacity: 0;
  transform: translateY(-35%);
}

.otgPreviewSection .otgLogo.cinemaType {
  width: 350px;
  height: 106px;
  margin-right: unset;
  margin-bottom: 75px;
  background: url("../../src/assets/images/game-page/otg-cinema-logo.png");
  background-size: 100% 100%;
}

.otgPreviewSection .platforms-icons {
  /*margin-top: 65px;*/
  margin-top: 60px;
  display: flex;
  height: 28px;

  /*for fadeIn animation boilerplate*/
  opacity: 0;
  transform: translateY(70%);
}

.otgPreviewSection .platform-icon {
  height: 100%;
  flex-shrink: 0;
  background-size: 100% 100%;
}

.otgPreviewSection .platform-icon:last-child {
  margin-left: 2px;
}

/*.otgPreviewSection .platform-icon.ps4 {*/
/*    width: 83px;*/
/*    background-image: url('../../src/assets/images/game-page/ps4-icon.svg');*/
/*}*/
.otgPreviewSection .platform-icon.ps5 {
  width: 99px;
  background-image: url("../../src/assets/images/game-page/ps5-icon.svg");
}
/*.otgPreviewSection .platform-icon.xbox-one {*/
/*    width: 98px;*/
/*    background-image: url('../../src/assets/images/game-page/xbox-one-icon.svg');*/
/*}*/
.otgPreviewSection .platform-icon.xbox-xs {
  width: 150px;
  background-image: url("../../src/assets/images/game-page/xbox-xs-icon.svg");
}
.otgPreviewSection .platform-icon.pc {
  width: 36px;
  background-image: url("../../src/assets/images/game-page/pc-icon.svg");
}

.otgPreviewSection .platform-icon.macos {
  width: 36px;
  background-image: url("../../src/assets/images/game-page/macos-icon.svg");
}

.otgPreviewSection .link-wrapper {
  width: fit-content;
  display: block;
  margin-top: -3%;
}

.otgPreviewSection .link-wrapper.cinemaType {
  margin-left: 25px;
}

/*min width*/
@media (min-width: 992px) {
  .otgPreviewSection .content {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .otgPreviewSection .content {
    max-width: 1280px;
  }
}

/*max width*/
@media (max-width: 992px) {
  .otgPreviewSection {
    padding-left: 15px;
    padding-right: 15px;
    /*background:*/
    /*        linear-gradient(rgba(0, 0, 0, 0.42) 100%, rgba(0, 0, 0, 0.42) 100%),*/
    /*        url('../../src/assets/images/game-page/OTG-preview-section(mobile).jpg');*/
    /*!*other bg props assignment by context(by 'current page' settings with forwarded class)*!*/
  }

  .otgPreviewSection .content {
    align-items: center;
  }

  /*styles for centring content in additional content > div wrapper(height handler for
parallax vertical transition*/
  .otgPreviewSection .otgLogo,
  .otgPreviewSection .redirect-button {
    margin-left: auto;
    margin-right: auto;
  }

  .otgPreviewSection .link-wrapper.cinemaType {
    margin-left: 27px;
  }
}

@media (max-width: 768px) {
  .otgPreviewSection {
    background: linear-gradient(
        rgba(0, 0, 0, 0.1) 100%,
        rgba(0, 0, 0, 0.1) 100%
      ),
      /* url('../../src/assets/images/game-page/OTG-preview-section(mobile).jpg');
       */
        url("../../src/assets/images/game-page/OTG-preview-section\(desktop-tablet\).png");
    /*other bg props assignment by context(by 'current page' settings with forwarded class)*/
  }

  .otgPreviewSection .content {
    align-items: center;
  }

  .otgPreviewSection .redirect-button {
    margin-top: 42px;
  }

  .otgPreviewSection .platforms-icons {
    margin-top: 47px;
  }
}

@media (max-width: 768px) {
  .otgPreviewSection .otgLogo {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 554px) {
  /*.otgPreviewSection .otgLogo {*/
  /*    width: 178px;*/
  /*    height: 141px;*/
  /*}*/

  .otgPreviewSection .platforms-icons {
    width: 286px;
    height: initial;
    /*flex-wrap: wrap;*/
    justify-content: center;
  }

  .otgPreviewSection .platform-icon {
    height: 28px;
  }

  .otgPreviewSection .platform-icon:nth-of-type(4) {
    margin-top: 10px;
    margin-left: 0;
  }

  .otgPreviewSection .platform-icon:nth-of-type(5) {
    margin-top: 10px;
  }

  .otgPreviewSection .otgLogo.cinemaType {
    width: 295px;
    height: 92px;
  }

  .otgPreviewSection .link-wrapper.cinemaType {
    margin-left: 0;
  }
}

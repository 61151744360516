.gamescom2022Slide {
    width: 100%;
    max-width: 100vw;
    height: 703px;
    padding: 0 60px;
    position: relative;
    font-weight: 400;
}

.gamescom2022Slide .grey-bg-holder {
    width: 75vw;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url('../../../src/assets/images/gamescome2022SlideContent/grey-bg.jpg');
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
    z-index: -1;
}

.gamescom2022Slide .gamescom-bg-and-logo-holder {
    width: 42vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    background-image: url('../../../src/assets/images/gamescome2022SlideContent/gamescom-bg.png');
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
    z-index: -1;
}

.gamescom2022Slide .eventLogo {
    background-image: url('../../../src/assets/images/gamescome2022SlideContent/gamescom-logo.svg');
    background-size: cover;
}

.gamescom2022Slide .content {
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.gamescom2022Slide .column-entities {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gamescom2022Slide .title {
    width: 610px;
    font-size: 80px;
    font-weight: 400;
    line-height: 80px;
    color: #ffffff;
    opacity: 0;
    transform: translateY(-15%);
    animation: fadeInFromTop15 0.6s ease-out 0.3s forwards;
}

.gamescom2022Slide .title span {
    font-weight: 700;
}

.gamescom2022Slide .event-description-items {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    line-height: 24px;
    color: #BBFF00;
    font-family: supply, sans-serif;
    opacity: 0;
    transform: translateY(20%);
    animation: fadeInFromBottom20 0.6s ease-out 0.325s forwards;
}

.gamescom2022Slide .link-wrapper {
    margin-top: 60px;
    width: fit-content;
    display: block;
    opacity: 0;
    transform: translateY(20%);
    animation: fadeInFromBottom20 0.6s ease-out 0.35s forwards;
}

.gamescom2022Slide .redirect-button { 
    margin-top: 0;
    opacity: 1;
    transform: initial;
}

/*---media queries---*/
/*min width*/
@media (min-width: 992px) {
    .gamescom2022Slide .content  {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .gamescom2022Slide .content  {
        max-width: 1280px;
    }

    .gamescom2022Slide .event-description-items {
        font-size: 20px;
    }

    .gamescom2022Slide .event-description-items {
        width: 610px;
    }

}

@media (min-width: 1400px) {
    .gamescom2022Slide .gamescom-bg-and-logo-holder {
        width: 48vw;
    }
    .gamescom2022Slide .eventLogo {
        margin-left: 7%;
        width: 421px;
        height: 305px;
    }

    .gamescom2022Slide .title {
        font-size: 90px;
    }
}


/*max width*/
@media (max-width: 1399px) {
    .gamescom2022Slide .title {
        width: 550px;
    }

    .gamescom2022Slide .eventLogo {
        margin-left: 8%;
        width: 335px;
        height: 243px;
    }
}




@media (max-width: 1199px) {
    .gamescom2022Slide .event-description-items {
        width: 550px;
        font-size: 18px;
    }
}

@media (max-width: 1099px) {
    .gamescom2022Slide .eventLogo {
        margin-left: 10%;
        width: 280px;
        height: 203px;
    }

    .gamescom2022Slide .event-description-items {
        width: 528px;
        font-size: 17px;
    }
}


@media (max-width: 992px) {
    .gamescom2022Slide {
        padding-left: 15px;
        padding-right: 15px;
    }

    .gamescom2022Slide .gamescom-bg-and-logo-holder {
        width: 48vw;
    }

    .gamescom2022Slide .eventLogo {
        margin-left: 12%;
    }

    .gamescom2022Slide .title {
        width: 440px;
        font-size: 58px;
        line-height: 58px;
    }

    .gamescom2022Slide .event-description-items {
        margin-top: 25px;
        width: initial;
        flex-direction: column;
        font-size: 17px;
        line-height: 16px;
    }

    .gamescom2022Slide .event-description-items div:not(.gamescom2022Slide .event-description-items div:first-child) {
        margin-top: 12px;
    }
}

@media (max-width: 768px) {
    .gamescom2022Slide {

        /*other bg props assignment by context(by 'current page' settings with forwarded class)*/
    }

    .gamescom2022Slide .grey-bg-holder {
        width: 100%;
        height: 100%;
        background-image: url('../../../src/assets/images/gamescome2022SlideContent/grey-bg-mobile.png');
    }

    .gamescom2022Slide .gamescom-bg-and-logo-holder {
        width: 85vw;
        height: 200px;
        left: 0;
        top: 50%;
        justify-content: flex-start;
        transform: translateY(-50%);
        background-image: url('../../../src/assets/images/gamescome2022SlideContent/gamescom-bg-mobile.png');
        background-size: cover;
        background-position: right center;
        background-repeat: no-repeat;
    }

    .gamescom2022Slide .eventLogo {
        margin-left: calc(49.3vw - 100px);
        width: 200px;
        height: 145px;
    }

    .gamescom2022Slide .column-entities {
        width: 100%;
        align-items: center;
    }

    .gamescom2022Slide .title {
        margin-top: 100px;
        width: 385px;
        text-align: center;
    }

    .gamescom2022Slide .event-description-items {
        margin-top: auto;
        font-size: 18px;
    }

    .gamescom2022Slide .link-wrapper {
        margin: 35px 0 60px;
    }
}



@media (max-width: 575px) {
    .gamescom2022Slide .title {
        margin-top: 110px;
        width: 385px;
        font-size: 42px;
        line-height: 50px;
    }
}

@media (max-width: 420px) {

    .gamescom2022Slide .eventLogo {
        margin-left: calc(49vw - 100px);
        width: 200px;
        height: 145px;
    }
}

@media (max-width: 370px) {
    .gamescom2022Slide .title {
        font-size: 38px;
        line-height: 46px;
    }
}

/*animations*/
@keyframes fadeInFromTop15 {
    0% {opacity: 0; transform: translateY(-15%)}
    100% {opacity: 1; transform: translateY(0)}
}

@keyframes fadeInFromBottom20 {
    0% {opacity: 0; transform: translateY(20%)}
    100% {opacity: 1; transform: translateY(0)}
}
.otg-cinema-slide {
  width: 100%;
  max-width: 100vw;
  height: 703px;
  padding: 0 60px;
  position: relative;
  font-weight: 400;
}

.otg-cinema-slide .red-bg-overlay-holder {
  width: 53vw;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url('../../assets/images/otg-cinema-slide-content/background-black-overlay.png');
  background-size: cover;
  background-position: right bottom;
  background-repeat: no-repeat;
  z-index: -1;
}

.otg-cinema-slide .red-bg-overlay-lining-holder {
  width: 5vw;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: black;
  z-index: -3;
}

.otg-cinema-slide .bg-image-holder {
  /* width: 62.847vw; */
  /* width: calc(80vw + (200px - 5vw)); */
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-image: url('../../assets/images/otg-cinema-slide-content/background-image.jpg');
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  z-index: -2;
}

.otg-cinema-slide .content {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Supply;
}

.otg-cinema-slide .black-logo {
  width: 450px;
  opacity: 0;
  transform: translateY(-15%);
  animation: fadeInFromTop15 0.6s ease-out 0.3s forwards;
}

.otg-cinema-slide .flex-wrapper {
  display: flex;
  flex-direction: column;
}

.otg-cinema-slide .title {
  margin-top: 30px;
  margin-bottom: 30px;
  /* max-width: 370px; */
  display: flex;
  flex-direction: column;
  font-size: 70px;
  font-weight: 500;
  line-height: 62px;
  color: #ff003a;
  text-transform: uppercase;
  word-spacing: -8px;
  letter-spacing: -2px;
  opacity: 0;
  transform: translateY(20%);
  animation: fadeInFromBottom20 0.6s ease-out 0.325s forwards;
}

.otg-cinema-slide .title span {
  color: #ffffff;
  font-weight: 400;
  /* margin-left: 10px; */
}

.otg-cinema-slide .title.ua span {
  order: 1;
  /*text-transform: none;*/
  font-family: SourceCodePro, sans-serif;
  letter-spacing: 1px;
}

.otg-cinema-slide .title.ua div {
  order: 2;
}

.otg-cinema-slide .redirection-button-wrap-link {
  margin-top: 30px;
  width: fit-content;
  position: relative;
  opacity: 0;
  transform: translateY(20%);
  animation: fadeInFromBottom20 0.6s ease-out 0.325s forwards;
}

.otg-cinema-slide .redirection-button {
  width: 250px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.95);
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 41%);
  border: 3px solid rgba(255, 255, 255, 1);
  box-sizing: border-box;
  transition: all ease-in 0.07s;
}

.otg-cinema-slide .redirection-button-wrap-link:hover .redirection-button {
  box-shadow: inset 0 0 0 2em rgba(255, 255, 255, 1);
  opacity: 0.95;
  color: black;
}

.redirection-button-wrap-link .inner-border-1,
.redirection-button-wrap-link .inner-border-2 {
  width: 0;
  height: 3px;
  position: absolute;
  transition: all 0s linear;
  background: black;
  z-index: 1;
}

.redirection-button-wrap-link .inner-border-3,
.redirection-button-wrap-link .inner-border-4 {
  width: 3px;
  height: 0;
  position: absolute;
  transition: all 0s linear;
  background: black;
  z-index: 1;
}
.redirection-button-wrap-link:hover .inner-border-1,
.redirection-button-wrap-link:hover .inner-border-2 {
  width: calc(100% - 6px);
}
.redirection-button-wrap-link:hover .inner-border-3,
.redirection-button-wrap-link:hover .inner-border-4 {
  height: calc(100% - 6px);
}

.redirection-button-wrap-link .inner-border-2 {
  left: 3px;
  bottom: 3px;
  transition-delay: 0s;
}
.redirection-button-wrap-link .inner-border-4 {
  transition-delay: 0s;
  right: 3px;
  bottom: 3px;
}
.redirection-button-wrap-link .inner-border-1 {
  right: 3px;
  top: 3px;
  transition-delay: 0s;
}
.redirection-button-wrap-link .inner-border-3 {
  transition-delay: 0s;
  left: 3px;
  top: 3px;
}

.redirection-button-wrap-link:hover .inner-border-2 {
  transition: all 0.15s linear;
  transition-delay: 0.03s;
}
.redirection-button-wrap-link:hover .inner-border-4 {
  transition: all 0.15s linear;
  transition-delay: 0.18s;
}
.redirection-button-wrap-link:hover .inner-border-1 {
  transition: all 0.15s linear;
  transition-delay: 0.33s;
}
.redirection-button-wrap-link:hover .inner-border-3 {
  transition: all 0.15s linear;
  transition-delay: 0.48s;
}

/*---media queries---*/
/*min width*/
@media (min-width: 992px) {
  .otg-cinema-slide .content {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .otg-cinema-slide .content {
    max-width: 1280px;
  }
}

/*max width*/
@media (max-width: 1200px) {
  .otg-cinema-slide .red-bg-overlay-lining-holder {
    display: none;
  }

  .otg-cinema-slide .bg-image-holder {
    background-image: url('../../assets/images/otg-cinema-slide-content/background-image-tablet.jpg');
    background-position-x: left;
  }

  .otg-cinema-slide .red-bg-overlay-holder {
    width: 65vw;
    background-image: url('../../assets/images/otg-cinema-slide-content/background-black-overlay-tablet.png');
  }
}

@media (max-width: 992px) {
  .otg-cinema-slide .redirection-button-wrap-link .inner-border-1,
  .otg-cinema-slide .redirection-button-wrap-link .inner-border-2,
  .otg-cinema-slide .redirection-button-wrap-link .inner-border-3,
  .otg-cinema-slide .redirection-button-wrap-link .inner-border-4 {
    display: none;
  }

  .otg-cinema-slide {
    padding: 0px 15px;
  }

  .otg-cinema-slide .redirection-button-wrap-link:hover .redirection-button {
    box-shadow: initial;
    opacity: 1;
    color: rgba(255, 255, 255, 0.95);
  }

  .otg-cinema-slide .content {
    justify-content: start;
    padding-top: 80px;
    gap: 50px;
  }

  .otg-cinema-slide .black-logo {
    width: 400px;
  }

  .otg-cinema-slide .title {
    font-size: 60px;
  }

  .otg-cinema-slide .redirection-button-wrap-link {
    margin-top: 0px;
    height: 25px;
  }

  .otg-cinema-slide .bg-image-holder {
    background-position: center;
  }
}

@media (max-width: 768px) {
  .otg-cinema-slide .red-bg-overlay-holder {
    width: 75vw;
  }
  /* .otg-cinema-slide .bg-image-holder {
    background-image: url('../../assets/images/otg-cinema-slide-content/background-image-tablet.jpg');
  }



  /* .otg-cinema-slide .title {
    font-size: 70px;
    flex-direction: row;
    gap: 15px;
  } */

  /* .otg-cinema-slide .black-logo {

    width: 60vw;
    height: calc(60vw / 100 * 30);
  } */

  /* .otg-cinema-slide .redirection-button-wrap-link {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  } */

  /* .otg-cinema-slide .redirection-button {
    border: 2px solid rgba(255, 255, 255, 1);
  } */

  /* .otg-cinema-slide .title {
    margin-top: 24px;
    margin-left: initial;
    max-width: 100%;
    font-size: 30px;
    line-height: 34px;
    word-spacing: -5px;
    text-align: center;
  } */
}

@media (max-width: 550px) {
  .otg-cinema-slide .content {
    padding-top: 105px;
    justify-content: flex-start;
    gap: 25px;
    align-items: center;
  }

  .otg-cinema-slide .bg-image-holder {
    background-image: url('../../assets/images/otg-cinema-slide-content/background-mobile.jpg');
  }

  .otg-cinema-slide .red-bg-overlay-holder {
    width: 100%;
    background-image: url('../../assets/images/otg-cinema-slide-content/background-black-overlay-mobile.png');
  }

  .otg-cinema-slide .black-logo {
    width: 85vw;
  }

  .otg-cinema-slide .title {
    font-size: 45px;
    margin-top: 24px;
    margin-left: initial;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    max-width: 100%;
    line-height: 34px;
    word-spacing: -5px;
    text-align: center;
  }

  .otg-cinema-slide .red-bg-overlay-holder {
    height: 85%;
  }
}

@media (max-width: 450px) {
  .otg-cinema-slide .title {
    font-size: 30px;
  }
}

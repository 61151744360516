.rslider{
    display: block;
    max-width: 100%;
}
.rslider-wrapper{
  overflow: hidden;
  max-width: 100%;
  position: relative;
  align-items: flex-start;
  height: 405px;
}
.rslider-content{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  transition: all cubic-bezier(.25,.75,.5,1.25) 1s;
  align-items: stretch;
}
.rslide.cols4{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 34px;
  row-gap: 34px;
}
.rslide.cols4 > *{
  overflow: hidden;
}
.rslide.cols1 > *{
  width: calc(100%);
}
.rslide{
    flex: 1;
}

.rslider-control{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 16px;
  align-items: center;
}
.rslider-control > div{
  width: 48px;
  height: 48px;
  margin-right: 18px;
  display: block;
  background-size: 24px 20px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  border: solid 0.5px rgba(175, 255, 1, 0.2);
  background-color: rgba(175, 255, 1, 0.12);
}
.rslider-control > div.true{
  opacity: 0.6;
  cursor: default;
}
.rslider-control a{
  margin-left: auto;
}
.rslider-control > .slide-left{
  background-image: url(../assets/images/left-arrow@2x.png);
}
.rslider-control > .slide-right{
  background-image: url(../assets/images/right-arrow@2x.png);
}
.gunz-page .header {
    padding-left: 60px;
    padding-right: 60px;
    width: 100% !important;
}

.gunz-page .header .col-md-2 {
    padding-left: 0;
}
.gunz-page .header .col-md-3.lang-cell {
    padding-right: 0;
}


.gunz-page__main-part {
    /*root padding-top and footer desk  top height*/
    min-height: calc(100vh - 140px);
    padding: 0 60px;
    overflow-x: hidden;
    font-weight: 400;
    letter-spacing: 0;
    color: #FAFAFA;
}

.gunz-page .footer {
    background-color: #000;
    padding-left: 60px;
    padding-right: 60px;
}

.gunz-page .footer .col-12.col-sm-12.col-md-12.col-lg-4.logo.logo-col {
    padding-left: 0;
}

.gunz-page .footer .col-12.col-sm-6.col-md-6.col-lg-3.iconsWhite.icons-col {
    padding-right: 0;
}

.gunz-page .footer .copyright.copyright-col {
    padding-left: 0;
    padding-right: 0;
}

.gunz-page__content {
    margin: 0 auto;
    max-width: 1280px;
    /*overflow-x: hidden;*/
    position: relative;
}

.gunz-page__title {
    /*max-width: 47.1%;*/
    margin-bottom: 0;
    font-family: Supply, sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 52px;
    text-transform: uppercase;
    color: #AFFF00;
}

.gunz-page__description {
    max-width: 52.1%;
    margin-top: 30px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(17%);
    animation: descriptionFadeInToTop 1.2s ease-out forwards 0.1s;
}

@keyframes descriptionFadeInToTop {
    from {
        opacity: 0;
        visibility: hidden;
        transform: translateY(17%)
    }
    to {
        opacity: 1;
        visibility: visible;
        transform: translateY(0)
    }
}

.gunz-page__description p {
    margin-bottom: 0;
    font-family: Supply, sans-serif;
    font-size: 20px;
    line-height: 30px;
}
.gunz-page__description p:last-child {
    margin-top: 30px;
}

.gunz-page__button {
    margin-top: 60px;
    min-width: 265px;
    padding: 14px;
    display: inline-block;
    font-family: Supply, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #afff01;
    border: 0.2px solid rgba(175,255,1,.2);
    background-color: rgba(175,255,1,.12);
    box-shadow: inset 0 0 0 0em #afff01;
    transition: box-shadow ease-in 0.07s, color ease-in 0.15s;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
    animation: buttonFadeInToTop 1.2s ease-out forwards 0.1s;
}

.gunz-page__button:hover {
    color: #000 !important;
    box-shadow: inset 0 0 0 2em #afff01;
    transition:  box-shadow ease-in 0.07s, color ease-in 0.15s;
}

@keyframes buttonFadeInToTop {
    from {
        opacity: 0;
        visibility: hidden;
        transform: translateY(100%)
    }
    to {
        opacity: 1;
        visibility: visible;
        transform: translateY(0)
    }
}


.gunz-page__socials {
    margin: 53px 0 100px;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    animation: socialsFadeInFromLeft 0.5s ease-in forwards 1.2s;
}

@keyframes socialsFadeInFromLeft {
    from {
        opacity: 0;
        visibility: hidden;
        transform: translateX(-4%)
    }
    to {
        opacity: 1;
        visibility: visible;
        transform: translateX(0)
    }
}

.gunz-page__socials-cta-title {
    font-family: Supply, sans-serif;
    font-size: 20px;
    line-height: 30px;
}

.gunz-page__socials-icons {
    margin-top: 20px;
    display: flex;
}

.gunz-page__socials-icon-wrap {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    border: 0.5px solid rgba(255, 255, 255, 0.4);
    transition: 0.2s;
}

.gunz-page__socials-link:last-child {
    margin-left: 20px;
}

.gunz-page__socials-icon-wrap .gunz-page__socials-icon {
    width: 25px;
    height: 21px;
    position: relative;
    /*background-image: url('./assets/images/icons/gunzPage-telegram-icon.svg');*/
    /*background-size: cover;*/
}

.gunz-page__socials-link:last-child .gunz-page__socials-icon {
    height: 19px;
    position: relative;
    /*background-image: url('./assets/images/icons/gunzPage-twitter-icon.svg');*/
}

/*after - realize of hover*/
.gunz-page__socials-icon:before,
.gunz-page__socials-icon:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.3s;
    opacity: 1;
}

.gunz-page__socials-icon:after {
    opacity: 0;
}

.gunz-page__socials-link .gunz-page__socials-icon:before {
    background-image: url('./assets/images/icons/gunzPage-telegram-icon.svg');
}
.gunz-page__socials-link:last-child .gunz-page__socials-icon:before {
    background-image: url('./assets/images/icons/gunzPage-twitter-icon.svg');
}
.gunz-page__socials-link .gunz-page__socials-icon:after {
    background-image: url('./assets/images/icons/gunzPage-telegram-icon--hover.svg');
}
.gunz-page__socials-link:last-child .gunz-page__socials-icon:after {
    background-image: url('./assets/images/icons/gunzPage-twitter-icon--hover.svg');
}

.gunz-page__socials-link:hover .gunz-page__socials-icon-wrap {
    background: rgba(175, 255, 1, 0.1);
    border: 0.5px solid rgba(175, 255, 1, 0.4);
}

.gunz-page__socials-link:hover .gunz-page__socials-icon:before {
    opacity: 0.6;
}

.gunz-page__socials-link:hover .gunz-page__socials-icon:after {
    opacity: 1;
}

/*
animated logo
4 elements:
.right-side-gear-logo - size container
    &:after - outer ring
.right-side-gear-logo-innerParts  - inner ring(bg)
    &:before - ring with orbit elepses
    &: after - g word

*/

.right-side-gear-logo {
    width: 47.1vw;
    height: 47.1vw;
    max-width: 605px;
    max-height: 605px;
    /*position: fixed;*/
    position: absolute;
    /*top: 140px;*/
    right: -20vw;
    /*top: 5px;*/
    overflow: hidden;
    /*z-index: -1;*/
    animation: gearLogoFadeInFromRight 1s ease-in forwards 0.1s;
}

@keyframes gearLogoFadeInFromRight {
    from {
        right: -20vw;
    }
    to {
        right: -14vw;
    }
}

.right-side-gear-logo:after,
.right-side-gear-logo-innerParts:before,
.right-side-gear-logo-innerParts:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
}

.right-side-gear-logo:after {
    width: 100%;
    height: 100%;
    background-image: url('./assets/images/icons/gunz-page-logo-outer-ring.svg');
    /*animation: rotateLogoFirstType 6s ease-in-out infinite 2s;*/
    animation: rotateLogoFirstType 6s ease-in-out  infinite 0.3s;
}

.right-side-gear-logo-innerParts {
    width: 87.575%;
    height: 87.575%;
    top: 6.2125%;
    left: 6.2125%;
    position: relative;
    background-image: url('assets/images/icons/gunz-page-logo-inner-ring.svg');
    background-size: cover;
}

.right-side-gear-logo-innerParts:before {
    width: 89.446%;
    height: 89.446%;
    left: 5.277%;
    top: 5.277%;
    background-image: url('assets/images/icons/gunz-page-logo-with-elipses-ring.svg');
    /*animation: rotateLogoCounterSecondType 6s ease-in-out infinite 2s;*/
    animation: rotateLogoSecondType 6s ease-out infinite;
}

.right-side-gear-logo-innerParts:after {
    width: 55.19%;
    height: 45.5%;
    left: 22.405%;
    top: 27.25%;
    background-image: url('./assets/images/icons/gunz-page-logo-g.svg');
}

@keyframes rotateLogoFirstType {
    0% {
        transform: rotate(0deg)
    }

    40% {
        transform: rotate(-45deg)
    }

    50% {
        transform: rotate(-45deg)
    }

    90% {
        transform: rotate(0deg)
    }
}

@keyframes rotateLogoSecondType {
    0% {
        transform: rotate(0deg)
    }

    40% {
        transform: rotate(-35deg)
    }

    50% {
        transform: rotate(-35deg)
    }

    90% {
        transform: rotate(0deg)
    }
}


@media screen and (min-width: 1401px) {
    .gunz-page__main-part {
        display: flex;
        align-items: center;
    }

    .gunz-page__socials {
        margin-top: 100px;
    }
}

@media screen and (max-width: 992px) {
    .gunz-page .header {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .gunz-page .header .col-6 {
        padding-left: 0;
    }
    .gunz-page .header .col-6.mob-menu {
        padding-right: 0;
    }

    .gunz-page .footer {
        background-color: #000;
        padding-left: 15px;
        padding-right: 15px;
    }

    .gunz-page .footer .col-12.col-sm-6.col-md-6.col-lg-5.subscribe-col {
        padding-left: 0;
    }

    .gunz-page__main-part {
        /*root padding-top and footer desk  top height*/
        min-height: calc(100vh - 140px);
        padding: 0 15px;
    }

    .gunz-page__title {
        max-width:  55.4%;
    }

    .gunz-page__description {
        max-width: 55.4%;
        margin-top: 40px;
    }

    .right-side-gear-logo {
        width: 57vw;
        height: 57vw;
        right: -35vw;
    }

    @keyframes gearLogoFadeInFromRight {
        from {
            right: -35vw;
        }
        to {
            right: -21vw;
        }
    }


    .gunz-page__button {
        margin-top: 48px;
    }

    .gunz-page__socials {
        margin-top: 48px;
    }

}

@media screen and (max-width: 768px) {
    .right-side-gear-logo {
        width: 100vw;
        height: 100vw;
        z-index: -1;
        opacity: 0.25;
        position: fixed;
        right: -60vw;
        top: 200px;
    }

    @keyframes gearLogoFadeInFromRight {
        from {
            right: -60vw;
        }
        to {
            right: -34vw;
        }
    }

    .gunz-page__title {
        max-width: 462px;
        font-size: 30px;
        line-height: 40px;
    }

    .gunz-page__description {
        max-width: 462px;
    }
}

@media screen and (max-width: 574px) {
    .gunz-page .footer .col-12.col-sm-12.col-md-12.col-lg-4.logo.logo-col {
        padding-right: 0;
    }

    .gunz-page .footer .col-12.col-sm-6.col-md-6.col-lg-5.subscribe-col {
        padding-right: 0;
    }

    .gunz-page .footer .col-12.col-sm-6.col-md-6.col-lg-3.iconsWhite.icons-col {
        padding-left: 0;
    }
}


@media screen and (max-width: 545px) {

    .gunz-page__socials {
        margin: 48px auto;
        width: fit-content;
    }

    .gunz-page__socials-cta-title {
        text-align: center;
    }

    .gunz-page__button {
        width: 100%;
    }

    .gunz-page__socials-icons {
        margin: 20px auto 0;
        width: fit-content;
    }
}

@media screen and (max-width: 350px) {
    .right-side-gear-logo {
        top: 230px;
    }
}
